import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { flatMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { IntimateClaim } from 'src/app/cp-member/models/Intimate-claim/intimate-claim.model';
import { IntimateSubmitClaim } from 'src/app/cp-member/models/Intimate-claim/intimate-submit-claim.model';
import { IntimateClaimDocument } from 'src/app/cp-member/models/Intimate-claim/intimate-claim-document.model';
import { SelfMember } from 'src/app/cp-member/models/self-member.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { IntimateClaimUploadDocuments } from 'src/app/cp-member/models/Intimate-claim/intimate-claim-upload-documents.model';
import { Router } from '@angular/router';
import { CPPolicyService } from '../../services/cp-policy.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CorporatePortalBaseComponent } from '../corporate-portal-base/corporate-portal-base.component';
import { ClaimJourney } from 'src/app/cp-member/models/track-claim/claim-journey.model';
import { SubmitFinalDeclaration } from '../../models/submit-final-declaration.model';
import { IntimateClaimCategory } from 'src/app/cp-member/models/Intimate-claim/intimate-claim-category.model';
import { BackendService } from 'src/app/shared/services/backend.service';
import { AbhaAddressId } from 'src/app/shared/enums/abha-address-id.enum';

@Component({
  selector: 'app-intimate-claim',
  templateUrl: './intimate-claim.component.html',
  styleUrls: ['./intimate-claim.component.scss'],
})
export class IntimateClaimComponent extends CorporatePortalBaseComponent implements OnInit {
  isHr: boolean;
  isMember: boolean;
  isHrView = false;

  empId = null;
  abhaId: string = null;
  abhaAddress: string = null;
  searchingMember = false;

  claimJourney = new ClaimJourney();
  isClaimJourneyLoaded = false;

  intimateClaim: IntimateClaim;
  causeOfHospitalizationValid = false;
  // claimCategoryValid = false;
  invalidCauseMsg = '';
  // invalidCategoryMsg = '';
  doctorNameValid = false;
  invalidDoctorNameMsg = '';
  invalidNameMsg = '';

  mobileNumberNotNull = true;
  mobileNumberValid = false;
  invalidMobileNumberMsg = '';

  emailNotNull = true;
  emailValid = false;
  invalidEmailMsg = '';

  claimCategory = '';

  claimCategoryCompleteList: IntimateClaimCategory[] =
  [{Category: 'Consultation',ServiceCode : '1062',ServiceType: '1'},
  { Category: 'Pharmacy', ServiceCode: '1063',ServiceType: '3' },
  { Category: 'Lab', ServiceCode: '1064',ServiceType: '7' },
  { Category: 'Others', ServiceCode: '1065' ,ServiceType: '14'},];

  claimCategoryList: Array<any> = [{text:'Consultation',value:'Consultation'}, {text:'Pharmacy',value:'Pharmacy'},{text: 'Blood reports/Lab',value:'Lab'}, {text:'Others',value:'Others'}];


  submitClaimDetails = true;

  intimatorNameValid = false;
  intimatorMobileNumberValid = false;
  hospitalNameValid = false;
  isHospitalChosen = false;
  invalidHospitalNameMsg = '';
  isAllMandatoryFilesUploaded = false;
  intimateClaimDisableNextButton = false;
  selectedMemberForTrackClaim: any;
  submitClaimData: IntimateSubmitClaim = new IntimateSubmitClaim();

  ClaimamountValid:boolean=true;

  invalidEmpId = false;
  invalidEmpIdMsg: string;

  //below variables are used for HR search process
  invalidAbhaId = false;
  invalidAbhaIdMsg: string;

  invalidAbhaAddress = false;
  invalidAbhaAddressMsg: string;

  //below variables are used for Intimation process
  abhaIdValid: boolean = false;
  abhaIdInvalidMsg: string;

  abhaIdAvailable: boolean = false;
  abhaAddressAvailable: boolean = false;

  abhaAddressValid: boolean = false;
  abhaAddressInvalidMsg: string;

  claimDocuments: IntimateClaimDocument[] = [];
  isClaimDocumentsLoaded = false;

  hospitalSearchResults: IntimateClaimDocument[] = [];
  isSearchDone = false;

  members: SelfMember[] = [];
  ismemberLoaded = false;

  isEmployeeBelongsToPolicy = false;

  claimType: string;

  currentStep = 1;
  file: any;

  policyType = '';

  errorList = [];
  errorR = [];
  error = null;
  isSizeValid = false;
  uploadImageInvalidMsg = '';
  imageInvalid = false;
  isSubmitClaim = false;
  firstRun = true;

  startDate = new Date();
  endDate = new Date();

  fileListDocs = [];

  intimatorName = '';
  isFinalDeclared: string;
  finalDeclarationData = new SubmitFinalDeclaration();
  isIntimatorSelf = true;
  allRelationships: any[] = [];
  selectedIntimator: string = '';
  invalidIntimatorMobileNumberMsg: string = '';
  invalidIntimatorNameMsg: string = '';
  fileArray: any = [];
  isClaimNumberOPD: boolean = false;
  isClaimNumberBlank: boolean = false;
  constructor(
    injector: Injector,
    policyService: CPPolicyService,
    authService: AuthService,
    private router: Router,
    private cpMemberService: CPMemberService,
    private cdr: ChangeDetectorRef
  ) {
    super(injector, policyService, authService);
  }

  async ngOnInit() {
    this.firstRun = true;
    this.policyService.setEmptyPolicy();
    this.intimateClaim = new IntimateClaim();
    this.claimCategory = '';
    this.policyType = window.sessionStorage.getItem('policyType');
    this.cpMemberService.isHrView.subscribe(v => {
      this.isHrView = v;
      this.empId = null;
      if (!this.isHrView) {
        this.searchingMember = false;
      } else if (this.isHrView) {
        this.cpMemberService.hrDetails.subscribe(hr => {
          this.intimatorName = hr.name;
        });
      }
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
     this.onNamePolicyChanged();

      if (this.policyNumber !== undefined && this.isMember && !this.isHrView && this.isEmployeeBelongsToPolicy) {
        this.cpMemberService.getMemberFamily(this.policyNumber).then(x => {
          this.cpMemberService.memberDetails.subscribe(m => {
            if (m.length > 0) {
            var skiprun = false;
             this.members.forEach(element => {
                const index = m.findIndex(member => member.uhid === element.uhid);
                if (index >= 0 && this.policyNumber != m[index].policyNo) {
                skiprun = true;
                }
              });
            if (!skiprun || this.firstRun) {
                this.members = m;
                this.firstRun = false;
                this.members.forEach(element => {
                  if (element.relationShipId.toUpperCase() === 'SELF') {
                    this.intimatorName = element.insuredName;
                  }
                  if (
                    element.relationShipId.toUpperCase() === 'SELF' &&
                    element.isSelfCovered.toLowerCase() === 'false'
                  ) {
                    const index = this.members.findIndex(member => member === element);
                    this.members.splice(index, 1);
                  }
                });
                this.ismemberLoaded = true;
              }
            }
          });
        });
      }
    });

  }

  toggleIntimator(flag: boolean){
    this.isIntimatorSelf = flag;
    this.checkIsSubmitClaimTrue();
    if(this.allRelationships.length==0)
      this.getAllRelationships();
  }
  getAllRelationships(){
    console.log(this.currentPolicy);
    this.cpMemberService.getAllRelationships().then(res => {
      this.allRelationships = res;
    })
  }
  onNamePolicyChanged() {
    this.ismemberLoaded = false;
    this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;
    this.isHr = this.cpMemberService.isHr;
    this.isMember = this.cpMemberService.isMember;
    this.errorR = [];
    this.searchingMember = false;
  }

  resetSubmitClaimsVariable() {
    this.causeOfHospitalizationValid = false;
    this.invalidCauseMsg = '';
    // this.invalidCategoryMsg='';
    // this.claimCategoryValid = false;
    this.doctorNameValid = false;
    this.invalidDoctorNameMsg = '';
    this.hospitalNameValid = false;
    this.invalidHospitalNameMsg = '';
    this.isAllMandatoryFilesUploaded = false;
    this.intimateClaimDisableNextButton = false;
    this.isIntimatorSelf = true;
    this.intimatorNameValid = false;
    this.intimatorMobileNumberValid = false;
    this.invalidIntimatorMobileNumberMsg = '';
    this.invalidIntimatorNameMsg = '';
    this.selectedIntimator = null;
    this.selectedMemberForTrackClaim = {};
    this.submitClaimData = new IntimateSubmitClaim();

    this.claimDocuments = [];
    this.isClaimDocumentsLoaded = false;

    this.hospitalSearchResults = [];
    this.isSearchDone = false;

    this.claimType = '';

    this.currentStep = 1;
    this.isFinalDeclared = undefined;

    this.abhaIdAvailable = false;
    this.abhaAddressAvailable = false;
  }

  async callIntimateClaim(member: SelfMember) {
    this.resetSubmitClaimsVariable();
    this.intimateClaim = new IntimateClaim();
    // this.members.forEach(element => {
    //   if (element.relationShipId === 'SELF') {
    //     this.intimateClaim.IntimatorName = element.insuredName;
    //     this.intimateClaim.MobileNumber = member.mobileNo;
    //     this.intimateClaim.PatientMobileNo = member.mobileNo;
    //     return;
    //   }
    // });
    // this.intimateClaim.IntimatorName = this.intimatorName;

    this.selectedMemberForTrackClaim = member;
    this.intimateClaim.MobileNumber = member.mobileNo;
    this.intimateClaim.ReceivedMode = 'CorporatePortal';
    if(!this.isHrView) 
      this.intimateClaim.IntimatorRelationship = 'Self';
    else 
      this.intimateClaim.IntimatorRelationship = 'HR';
    this.intimateClaim.PatientMobileNo = member.mobileNo;
    this.intimateClaim.InsuredName = member.insuredName;
    this.intimateClaim.EmployeeID = member.memberIdEmpId;
    this.intimateClaim.EmailID = member.emailID;
    this.intimateClaim.Age = member.age;
    this.intimateClaim.RelationShip = member.relationShipId;
    this.intimateClaim.PatientName = member.insuredName;
    this.intimateClaim.UHIDNumber = member.uhid;
    this.intimateClaim.PatientUHID = member.uhid;
    this.intimateClaim.AbhaId = member.insuredABHAId;
    this.intimateClaim.AbhaAddress = member.insuredABHAAddress;
    this.intimateClaim.PolicyName = '';
    this.intimateClaim.PolicyNumber = this.currentPolicy.policyNumber;
    this.policyType = this.currentPolicy.policyType;
    this.intimateClaim.PolicyStartDate = this.currentPolicy.riskStartDate;
    this.intimateClaim.PolicyEndDate = this.currentPolicy.riskEndDate;
    this.startDate = new Date(this.currentPolicy.riskStartDate);
    this.endDate = new Date(this.currentPolicy.riskEndDate);
    // this.cpMemberService
    //   .getIntimateClaimDocuments()
    //   .then(x => {
    //     this.claimDocuments = x;
    //     this.isClaimDocumentsLoaded = true;
    //   })
    //   .catch(errorData => {
    //     console.log('error');
    //   });
    if(member.insuredABHAId == '' || member.insuredABHAId == undefined || member.insuredABHAId == null){
      this.abhaIdAvailable = false;
    }
    else{
      this.abhaIdAvailable = true;
    }
    if(member.insuredABHAAddress == '' || member.insuredABHAAddress == undefined || member.insuredABHAAddress == null){
      this.abhaAddressAvailable = false;
    }
    else{
      this.abhaAddressAvailable = true;
    }
  }

  async searchByHospitalName() {

    if (this.intimateClaim.HospitalName.trim() !== '' && this.intimateClaim.HospitalName.length > 2) {
      await this.cpMemberService
        .searchHospital(this.intimateClaim.HospitalName, 0)
        .then(x => {
          this.hospitalSearchResults = x;
          this.isSearchDone = true;
        })
        .catch(x => {
          console.log('error');
        });
    }
  }

  async searchIntimateClaimsByEmpId() {
    this.searchingMember = true;
    this.ismemberLoaded = false;
    await this.cpMemberService
      .searchIntimateClaimsByEmpId(this.policyNumber, this.empId, this.abhaId, this.abhaAddress)
      .then(x => {
        this.searchingMember = true;
        this.members = x;
        const role = this.authService.role;
        if (role === 'Broker' || role === 'SM') {
          this.members.forEach(element => {
            if (element.relationShipId === 'SELF') {
              this.intimatorName = element.insuredName;
              this.intimateClaim.IntimatorName = this.intimatorName;
            }
          });
        }

        this.members.forEach(element => {
          if (element.relationShipId === 'SELF' && element.isSelfCovered.toLowerCase() === 'false') {
            const index = this.members.findIndex(member => member === element);
            this.members.splice(index, 1);
          }
        });
        this.ismemberLoaded = true;
      })
      .catch(errorData => {
        console.log('error');
        this.errorR = errorData.error.errors;
        this.error = errorData.error.errors[0].message;
        this.members = [];
        this.ismemberLoaded = true;
      });
  }

  setSearchByEmpId(event) {
    this.members = [];
    this.abhaId = null;
    this.invalidAbhaId = false;
    this.invalidAbhaIdMsg = '';
    this.abhaAddress = null;
    this.invalidAbhaAddress = false;
    this.invalidAbhaAddressMsg = '';
    this.searchingMember = false;
    this.ismemberLoaded = false;
    const empId = event.target.value;
    if (empId === '') {
      this.invalidEmpId = true;
      this.invalidEmpIdMsg = '';
    } else {
      if (/^\S(.*\S)?$/g.test(empId)) {
        this.invalidEmpId = false;
        this.invalidEmpIdMsg = '';
      } else {
        this.invalidEmpId = true;
        this.invalidEmpIdMsg = 'Please enter valid Employee Id';
      }
    }
  }

  setSearchByAbhaId(event){
    this.members = [];
    this.empId = null;
    this.invalidEmpId = false;
    this.invalidEmpIdMsg = '';
    this.abhaAddress = null;
    this.invalidAbhaAddress = false;
    this.invalidAbhaAddressMsg = '';
    this.searchingMember = false;
    this.ismemberLoaded = false;
    const abhaId = event.target.value;
    if (abhaId === '') {
      this.invalidAbhaId = true;
      this.invalidAbhaIdMsg = '';
    } else {
      if (/^[0-9]{14}$/g.test(abhaId)) {
        this.invalidAbhaId = false;
        this.invalidAbhaIdMsg = '';
      } else {
        this.invalidAbhaId = true;
        this.invalidAbhaIdMsg = 'Please enter valid Abha Id';
      }
    }
  }

  setSearchByAbhaAddress(event){
    this.members = [];
    this.empId = null;
    this.invalidEmpId = false;
    this.invalidEmpIdMsg = '';
    this.abhaId = null;
    this.invalidAbhaId = false;
    this.invalidAbhaIdMsg = '';
    this.searchingMember = false;
    this.ismemberLoaded = false;
    const abhaAddress = event.target.value;
    if (abhaAddress === '') {
      this.invalidAbhaAddress = true;
      this.invalidAbhaAddressMsg = '';
    } else {
      if (/^[a-zA-Z]{1}[a-zA-Z0-9\.]*[a-zA-Z0-9]+@abdm$/g.test(abhaAddress) && abhaAddress.length >= 8 && abhaAddress.length <= 30) {
        this.invalidAbhaAddress = false;
        this.invalidAbhaAddressMsg = '';
      } else {
        this.invalidAbhaAddress = true;
        this.invalidAbhaAddressMsg = 'Please enter valid Abha Address';
      }
    }
  }

  // Choose claim type
  step1(event) {
    this.claimType = event;
    if(this.claimType == "Cashless IPD"){
      this.startDate = new Date(this.currentPolicy.riskStartDate);
      this.endDate = new Date(this.currentPolicy.riskEndDate);
    }
    else{
      this.startDate = new Date(this.currentPolicy.riskStartDate);
      this.endDate = new Date(); // For Reimbursement IPD and OPD the user should not be able to select future dates 
    }
    this.claimType === 'Cashless IPD'
      ? (this.intimateClaim.IntimationType = 'AL')
      : (this.intimateClaim.IntimationType = 'CL');
    this.claimType === 'Reimbursement OPD'
      ? (this.intimateClaim.IntimationType = 'CLOPD')
      : (this.intimateClaim.IntimationType = this.intimateClaim.IntimationType);
    this.loadDefaultDocs('');
    this.currentStep = 2;
    if (this.claimType === 'Cashless IPD') {
      if (this.intimateClaim.HospitalId !== null && this.hospitalNameValid === true) {
        this.isHospitalChosen = true;
      } else {
        this.isHospitalChosen = false;
      }
    } else {
      if (this.hospitalNameValid === true) {
        this.isHospitalChosen = true;
      } else {
        this.isHospitalChosen = false;
      }
    }
  }

  clearDischargeDate() {
    this.intimateClaim.DischargeDate = '';
  }

  admissionDateChanged() {
    if (
      this.intimateClaim.DischargeDate !== '' &&
      this.intimateClaim.DischargeDate < this.intimateClaim.AdmissionDate
    ) {
      this.intimateClaim.DischargeDate = '';
    }
  }

  clearAdmissionDate() {
    this.intimateClaim.AdmissionDate = '';
    this.intimateClaim.DischargeDate = '';
  }
  loadDefaultDocs(category: string) {
  if(category == '') 
  {
    this.claimCategory = category;
  }
  else{
      let y= this.claimCategoryCompleteList.find(a => a.Category === category);
      this.claimCategory = y.Category;
      this.intimateClaim.ServiceCode = y.ServiceCode;
      this.intimateClaim.ServiceType = y.ServiceType;
      // this.claimCategoryValid=true;
      // this.invalidCategoryMsg = '';
      // //this.selectedclaimCategory=y;
    }
  this.cpMemberService
      .getIntimateClaimDocuments(this.intimateClaim.IntimationType)
      .then(x => {
        this.claimDocuments = x;
        this.isClaimDocumentsLoaded = true;

      })
      .catch(errorData => {
        console.log('error');
      });

  }
  inValidateMembers(event) {
    this.ismemberLoaded = false;
    this.cdr.detectChanges();
  }
  loadDocumentList(category: string) {
    if (!this.isClaimDocumentsLoaded) {
      this.loadDefaultDocs(category);
    }

    if (category != '') {
      this.cpMemberService
        .getIntimateClaimDocumentsOPD(category)
        .then(x => {
        this.claimDocuments = [];
          x.forEach(element => {
            element.documentId = element.document_id;
            this.claimDocuments.push(element);
          });
        // const docFilter = x.map(item => item.description);
        // this.claimDocuments = this.claimDocuments.filter(item => docFilter.includes(item.description));
        // this.isClaimDocumentsLoaded = true;
        })
        .catch(errorData => {
          console.log('error');
        });
    }
  }

  causeOfHospitalization(event) {
    if (/^\s/.test(this.intimateClaim.DiagnosisDetails)) {
      this.causeOfHospitalizationValid = false;
      this.invalidCauseMsg = 'Please enter valid cause';
    } else if (event.target.value.length < 10) {
      this.causeOfHospitalizationValid = false;
      this.invalidCauseMsg = 'Enter minimum 10 characters';
    } else {
      this.causeOfHospitalizationValid = true;
      this.invalidCauseMsg = '';
    }
  }

  HospitalNameEntered() {
    this.intimateClaim.HospitalId = null;
    this.intimateClaim.Address1 = null;
    this.intimateClaim.Address2 = null;
    this.intimateClaim.Address3 = null;
    this.intimateClaim.State = null;
    this.intimateClaim.City = null;
    this.intimateClaim.District = null;
    this.intimateClaim.Pincode = null;
    this.hospitalSearchResults = [];
    if (/^\s/.test(this.intimateClaim.HospitalName) || this.intimateClaim.HospitalName.length === 0) {
      this.hospitalNameValid = false;
      this.invalidHospitalNameMsg = 'Please enter valid Hospital name';
    } else {
      this.hospitalNameValid = true;
      this.invalidHospitalNameMsg = '';
    }
    if (this.claimType === 'Cashless IPD') {
      this.isHospitalChosen = false;
    } else {
      this.isHospitalChosen = true;
    }
  }

  HospitalChosen(hospital) {
    this.isHospitalChosen = true;
    console.log(hospital);
    this.intimateClaim.HospitalId = hospital.hospitalId;
    this.intimateClaim.HospitalName = hospital.hospitalName;
    this.intimateClaim.Address1 = hospital.address1;
    this.intimateClaim.Address2 = hospital.address2;
    this.intimateClaim.Address3 = '';
    this.intimateClaim.State = hospital.state;
    this.intimateClaim.City = hospital.city;
    this.intimateClaim.District = hospital.district;
    this.intimateClaim.Pincode = hospital.pincode;
    this.hospitalSearchResults = [];
    this.hospitalSearchResults.push(hospital);
  }

  DoctorNameEntered() {
    if (/^s*([a-zA-Z][a-zA-Z. ]*)s*$/.test(this.intimateClaim.DoctorName) && this.intimateClaim.DoctorName.length > 2) {
      this.doctorNameValid = true;
      this.invalidDoctorNameMsg = '';
    } else {
      this.doctorNameValid = false;
      this.invalidDoctorNameMsg = 'Please enter valid Doctor name (Minimum 3 characters)';
    }
  }

  async convertFiletoBase64(file) {
    const result_base64 = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = e => resolve(fileReader.result);
      fileReader.onerror = e => reject(e);
    });
    return result_base64;
  }

  convertFilesArrayToBase64Array() {
    this.intimateClaim.DocumentList.forEach(element => {
      if (element.FileBytes instanceof File) {
        this.convertFiletoBase64(element.FileBytes).then(FileByte => {
          const filebyteInBase64 = String(FileByte).split('base64,')[1];
          const index1 = this.intimateClaim.DocumentList.findIndex(document => document === element);
          if (index1 !== -1) {
            this.intimateClaim.DocumentList[index1].FileBytes = filebyteInBase64;
          }
        });
      }
    });
  }

  fileSelectionEvent(event, doc) {
    const files = event.target.files;
    console.log(typeof files[0]);
    const fileNameArrayInUI = [];
    if (files.length > 0) {
      if (this.intimateClaim.DocumentList.length !== 0) {
        this.intimateClaim.DocumentList = this.removeFileFromIntimateDocuments(
          this.intimateClaim.DocumentList,
          doc.documentId
        );
        this.fileListDocs = this.removeFromFileListDocs(this.fileListDocs, doc.documentId);
      }

      for (const file of files) {
        const File: IntimateClaimUploadDocuments = {
          FileName: file.name,
          FileBytes: file,
          DocTypeId: doc.documentId,
          OriginalFile: file,
          Description: doc.description
        };
        this.intimateClaim.DocumentList.push(File);

        let fileNameInUi = file.name;
        if (fileNameInUi.length > 30) {
          fileNameInUi = fileNameInUi.slice(0, 40) + '...';
        }
        fileNameArrayInUI.push(fileNameInUi);
        let multipleFilesNameInUi = '';
        multipleFilesNameInUi = fileNameArrayInUI.join(', ');

        (<HTMLInputElement>document.getElementById(doc.documentId + 'para')).innerText = multipleFilesNameInUi;
        document.getElementById(doc.documentId + 'para').style.display = 'block';
        document.getElementById(doc.documentId + 'delete').style.display = 'block';
      }

      for (const file of files) {
        const size = file.size;
        const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
        if (!fileExtensionArr.includes(ext.toLowerCase())) {
          this.isSizeValid = false;
          this.uploadImageInvalidMsg = file.name + ' is invalid. Upload Valid Format File(Maximum 5 Mb)';
          (<HTMLInputElement>(
            document.getElementById(doc.documentId + 'invalid')
          )).innerText = this.uploadImageInvalidMsg;
          document.getElementById(doc.documentId + 'invalid').style.display = 'block';
          break;
        } else {
          this.isSizeValid = true;
          this.uploadImageInvalidMsg = '';
          (<HTMLInputElement>(
            document.getElementById(doc.documentId + 'invalid')
          )).innerText = this.uploadImageInvalidMsg;
          document.getElementById(doc.documentId + 'invalid').style.display = 'none';
        }
        if (this.isSizeValid) {
          if (size > 5000000) {
            this.isSizeValid = false;
            this.uploadImageInvalidMsg = file.name + ' is invalid. Maximum file size must be 5 Mb.';
            (<HTMLInputElement>(
              document.getElementById(doc.documentId + 'invalid')
            )).innerText = this.uploadImageInvalidMsg;
            document.getElementById(doc.documentId + 'invalid').style.display = 'block';
            break;
          } else {
            this.isSizeValid = true;
            this.uploadImageInvalidMsg = '';
            (<HTMLInputElement>(
              document.getElementById(doc.documentId + 'invalid')
            )).innerText = this.uploadImageInvalidMsg;
            document.getElementById(doc.documentId + 'invalid').style.display = 'none';
          }
        }

        document.getElementById(doc.documentId + 'delete').style.display = 'block';
        document.getElementById(doc.documentId + 'para').style.display = 'block';
      }

      const FileCopyForValidation = {
        documentID: doc.documentId,
        isImageValid: this.isSizeValid,
      };

      this.fileListDocs.push(FileCopyForValidation);
      for (let i = 0; i < this.fileListDocs.length; i++) {
        if (this.fileListDocs[i].isImageValid === false) {
          this.imageInvalid = true;
          break;
        } else {
          this.imageInvalid = false;
        }
      }

      // this.claimDocuments.forEach(element => {
      //   if (element.isMandatory === true) {
      //     const index = this.intimateClaim.DocumentList.findIndex(
      //       document => document.DocTypeId === element.documentId
      //     );
      //     if (index === -1 ) {
      //       this.intimateClaimDisableNextButton = true;
      //       return;
      //     } else {
      //       this.intimateClaimDisableNextButton = false;
      //     }
      //   }
      // });
      for(let i=0; i<this.claimDocuments.length; i++){
        const element = this.claimDocuments[i];
        if(element.isMandatory === true){
          const index = this.intimateClaim.DocumentList.findIndex(
            doc => doc.DocTypeId === element.documentId
          );
          if(index === -1){
            this.intimateClaimDisableNextButton = true;
            break;
          } 
          this.intimateClaimDisableNextButton = false;
        }
      }
      // this.checkInitiateClaimButton();
      this.convertFilesArrayToBase64Array();
    }
    console.log(this.intimateClaimDisableNextButton, 'intimateclaimnextbutton');
    console.log(this.imageInvalid, 'Image invaliddd');
    console.log(this.fileListDocs, 'filelistdocs');
    console.log(this.intimateClaim.DocumentList, 'documentlist');
  }

  checkInitiateClaimButton(){
    //this method checks if all the mandatory files in the claim documents is selected 
    //if not selected then the initiate claim button will be disabled
    for (let i = 0; i < this.claimDocuments.length; i++) {
      const element = this.claimDocuments[i];
      if(element.isMandatory === true){
        let index = this.intimateClaim.DocumentList.findIndex(doc => doc.DocTypeId === element.documentId);
        if(index === -1){
          this.intimateClaimDisableNextButton = true;
          return;
        }
        this.intimateClaimDisableNextButton = false;
      }
    }
  }

  removeFileFromIntimateDocuments(documentArray, documentId) {
    return documentArray.filter(el => el.DocTypeId !== documentId);
  }
  removeFromFileListDocs(documentArray, documentId) {
    return documentArray.filter(el => el.documentID !== documentId);
  }

  deleteFile(doc) {
    document.getElementById(doc.documentId + 'invalid').style.display = 'none';
    document.getElementById(doc.documentId + 'delete').style.display = 'none';
    document.getElementById(doc.documentId + 'para').style.display = 'none';
    (<HTMLInputElement>document.getElementById(doc.documentId + 'para')).innerText = '';
    (<HTMLInputElement>document.getElementById(doc.documentId + 'invalid')).innerText = '';
    this.intimateClaim.DocumentList = this.removeFileFromIntimateDocuments(
      this.intimateClaim.DocumentList,
      doc.documentId
    );
    this.fileListDocs = this.removeFromFileListDocs(this.fileListDocs, doc.documentId);
    console.log(this.intimateClaim.DocumentList);
    console.log(this.fileListDocs);

    this.claimDocuments.forEach(element => {
      if (element.isMandatory === true) {
        const index = this.intimateClaim.DocumentList.findIndex(document => document.DocTypeId === element.documentId);
        if (index === -1) {
          this.intimateClaimDisableNextButton = true;
          return;
        } else {
          this.intimateClaimDisableNextButton = false;
        }
      }
    });
    console.log(this.intimateClaimDisableNextButton, 'Intimate Claim Disable Button');

    for (let i = 0; i < this.fileListDocs.length; i++) {
      if (this.fileListDocs[i].isImageValid === false) {
        if (this.claimType != 'Reimbursement OPD') {
                 this.imageInvalid = true;
        }
       break;
      } else {
        this.imageInvalid = false;
      }
    }
    console.log(this.intimateClaimDisableNextButton, 'intimateclaimnextbutton');
    console.log(this.imageInvalid, 'Image invaliddd');
    console.log(this.fileListDocs, 'filelistdocs');
    console.log(this.intimateClaim.DocumentList, 'documentlist');
  }

  showFilenameOnPreviousOrNextButton() {
    this.claimDocuments.forEach(claimDocument => {
      const index = this.intimateClaim.DocumentList.findIndex(
        document => document.DocTypeId === claimDocument.documentId
      );
      if (index !== -1) {
        let fileNameInUI = this.intimateClaim.DocumentList[index].FileName;
        const docId = this.intimateClaim.DocumentList[index].DocTypeId;
        if (fileNameInUI.length > 30) {
          fileNameInUI = fileNameInUI.slice(0, 40) + '...';
        }
        document.getElementById(docId + 'delete').style.display = 'block';
        document.getElementById(docId + 'para').style.display = 'block';
        (<HTMLInputElement>document.getElementById(docId + 'para')).innerText = fileNameInUI;
      }
    });
  }

  previousStepBeforeSubmit() {
    this.previousStep();
    // this.showFilenameOnPreviousOrNextButton();
  }

  // What is the cause of hospitalization
  step2() {
    console.log(this.intimateClaim.IntimationType);
    this.loadDocumentList(this.claimCategory);
    this.currentStep = 3;
    this.resetAbhaVariables();
    if(this.intimateClaim.AbhaId != null  && this.intimateClaim.AbhaId != '' && this.intimateClaim.AbhaId != undefined){
      this.abhaIdEnteredForIntimation();
    } 
    if(this.intimateClaim.AbhaAddress != null && this.intimateClaim.AbhaAddress != '' && this.intimateClaim.AbhaAddress != undefined){
      this.abhaAddressEnteredForIntimation();
    }
  }

  resetAbhaVariables(){
    this.abhaIdValid = false;
    this.abhaAddressValid = false;
    this.abhaIdInvalidMsg = '';
    this.abhaAddressInvalidMsg = '';
  }

  abhaIdEnteredForIntimation(){
    if(this.intimateClaim.AbhaId == null || this.intimateClaim.AbhaId == undefined || this.intimateClaim.AbhaId == ''){
      this.abhaIdAvailable = false;
    }
    else{
      this.abhaIdAvailable = true;
    }
    if(/^[0-9]{14}$/g.test(this.intimateClaim.AbhaId)){
      this.abhaIdValid = true;
      this.abhaIdInvalidMsg = '';
    }
    else{
      this.abhaIdValid = false;
      this.abhaIdInvalidMsg = 'Please enter valid Abha Id';
    }
  }

  abhaAddressEnteredForIntimation(){
    if(this.intimateClaim.AbhaAddress == null || this.intimateClaim.AbhaAddress == undefined || this.intimateClaim.AbhaAddress == ''){
      this.abhaAddressAvailable = false;
    }
    else{
      this.abhaAddressAvailable = true;
    }
    if(/^[a-zA-Z]{1}[a-zA-Z0-9\.]*[a-zA-Z0-9]+@abdm$/g.test(this.intimateClaim.AbhaAddress) && this.intimateClaim.AbhaAddress.length >= 8 && this.intimateClaim.AbhaAddress.length <= 30){
      this.abhaAddressValid = true;
      this.abhaAddressInvalidMsg = '';
    }
    else{
      this.abhaAddressValid = false;
      this.abhaAddressInvalidMsg = 'Please enter valid Abha Address';
    }
  }

  // Share details of the hospitalization
  step3() {
    if(this.intimateClaim.ClaimAmount ==undefined || this.intimateClaim.ClaimAmount == null || this.intimateClaim.ClaimAmount<=0)
    {
      this.ClaimamountValid=false;
      return;
    }
    console.log(this.intimateClaim);
    // if(this.claimType=='Reimbursement OPD'){
    //  this.imageInvalid = false;
    //  this.intimateClaimDisableNextButton=false;
    // }
    //   else{
    //  this.imageInvalid = true;
    //  this.intimateClaimDisableNextButton=true;
    //  }
    this.claimDocuments.sort(claimDocument => {
      return claimDocument.isMandatory === false ? 0 : claimDocument ? -1 : 1;
    });
    // this.checkInitiateClaimButton();
    this.claimDocuments.forEach(element => {
      if (element.isMandatory === true) {
        const index = this.intimateClaim.DocumentList.findIndex(document => document.DocTypeId === element.documentId);
        if (index === -1) {
          this.intimateClaimDisableNextButton = true;
          return;
        } else {
          this.intimateClaimDisableNextButton = false;
        }
      }
    });
    for (let i = 0; i < this.fileListDocs.length; i++) {
      if (this.fileListDocs[i].isImageValid === false) {
        if (this.claimType != 'Reimbursement OPD') {
              this.imageInvalid = true;
        }
        break;
      } else {
        this.imageInvalid = false;
      }
    }
    console.log(this.fileListDocs);
    console.log(this.imageInvalid, 'Image invaliddd');
    console.log(this.intimateClaimDisableNextButton, 'kdddddddddddddddddddddddddddddd');
    this.currentStep = 4;
    // this.showFilenameOnPreviousOrNextButton();
  }

  // Upload the below documentation
  step4() {
    console.log(this.intimateClaimDisableNextButton, 'intimateclaimdisablenextbutton');
    console.log(this.intimateClaim.DocumentList, 'documents');
    this.currentStep = 4.5;
  }
  step45(){
    this.currentStep = 5;
  }

  step5() {
    this.currentStep = 6;
    if (
      this.intimateClaim.EmailID === '' ||
      this.intimateClaim.EmailID === undefined ||
      this.intimateClaim.EmailID === null
    ) {
      this.emailValid = false;
      this.invalidEmailMsg = '';
      this.emailNotNull = false;
    } else {
      this.emailEntered();
    }

    if (
      this.intimateClaim.MobileNumber === '' ||
      this.intimateClaim.MobileNumber === undefined ||
      this.intimateClaim.MobileNumber === null
    ) {
      this.mobileNumberValid = false;
      this.invalidMobileNumberMsg = '';
      this.mobileNumberNotNull = false;
    } else {
      this.mobileNumberEntered();
    }
    this.checkIsSubmitClaimTrue();
    console.log(this.isFinalDeclared);
  }
  // Submit
  async step6() {
    if(this.intimateClaim.IntimatorRelationship!=='HR' && this.selectedIntimator !=='')
      this.intimateClaim.IntimatorRelationship = this.selectedIntimator;
    
    if(this.isIntimatorSelf){
      this.intimateClaim.IntimatorName = this.intimatorName;
      this.intimateClaim.IntimatorMobileNo = this.intimateClaim.MobileNumber;
      this.intimateClaim.IntimatorRelationship = 'SELF';
    }
    if(!this.isIntimatorSelf && this.intimateClaim.MobileNumber === "") {
      this.intimateClaim.MobileNumber = this.intimateClaim.IntimatorMobileNo;
    }
    if(this.intimateClaim.IntimatorName == null || this.intimateClaim.IntimatorName == undefined || this.intimateClaim.IntimatorName == ""){
      this.intimateClaim.IntimatorName = this.intimateClaim.InsuredName;
    }
    this.intimateClaim.PolicyStartDate = moment(this.intimateClaim.PolicyStartDate).format('DD MMMM YYYY');
    this.intimateClaim.PolicyEndDate = moment(this.intimateClaim.PolicyEndDate).format('DD MMMM YYYY');
    if ('AdmissionDate' in this.intimateClaim) {
      this.intimateClaim.AdmissionDate = moment(this.intimateClaim.AdmissionDate).format('YYYY-MM-DD');
    }

    if (
      'DischargeDate' in this.intimateClaim &&
      this.intimateClaim.DischargeDate !== null &&
      this.intimateClaim.DischargeDate !== undefined &&
      this.intimateClaim.DischargeDate !== ''
    ) {
      this.intimateClaim.DischargeDate = moment(this.intimateClaim.DischargeDate).format('DD MMMM YYYY');
    }
    this.fileArray = [];
    for (let index = 0; index < this.intimateClaim.DocumentList.length; index++) {
      this.fileArray.push(this.intimateClaim.DocumentList[index].OriginalFile);
    }
    // Patient Abha Id and Address will be the member that we are raising claim for(might be self or dependent)
    this.intimateClaim.PatientABHAId = this.intimateClaim.AbhaId;
    this.intimateClaim.PatientABHAAddress = this.intimateClaim.AbhaAddress;

    //Insured Abha Id and Address will be self member Abha Id and Address
    this.members.forEach(member => {
      if(member.relationShipId.toUpperCase() === 'SELF'){
        this.intimateClaim.InsuredABHAId = member.insuredABHAId;
        this.intimateClaim.InsuredABHAAddress = member.insuredABHAAddress;
      }
    })

    const data = new FormData();
    this.fileArray.forEach(file => {
      data.append('File', file);
    });

    data.append('Json', JSON.stringify(this.intimateClaim));
    this.isSubmitClaim = true;
    await this.cpMemberService
      .submitClaims(data)
      .then(async x => {
        this.submitClaimData = x;
        if(this.checkIfClaimNumberIsOPD(this.submitClaimData.claimNumber+'')){
          this.isClaimNumberOPD = true;
          this.isClaimNumberBlank = false;
        }
        else if(this.checkIfClaimNumberIsBlank(this.submitClaimData.claimNumber+'')){
          this.isClaimNumberOPD = false;
          this.isClaimNumberBlank = true;
        }
        else {
          this.isClaimNumberOPD = false;
          this.isClaimNumberBlank = false;
        }
        this.currentStep = 7;
        this.isSubmitClaim = false;
        this.isClaimJourneyLoaded = false;
        this.submitDeclaration(x.claimNumber);
        await this.cpMemberService
          .getClaimsJournery(x.claimNumber)
          .then(cj => {
            this.claimJourney = cj;
            this.isClaimJourneyLoaded = true;
            let lastStage = 11;
            for (let i = 1; i < 11; i++) {
              const v = this.claimJourney['s' + i]['isCurrentState'];
              if (i > lastStage) {
                this.claimJourney['s' + i] = null;
              }

              if (v === true) {
                lastStage = i;
              }
            }
          })
          .catch(cj => {
            console.log('error');
          });
      })
      .catch(errorData => {
        console.log('error');
        this.errorList = errorData.error.errors;
        this.isSubmitClaim = false;
      });
  }

  // Status
  // step6() {
  //   this.router.navigate(['/cp-member/track-claims']);
  //   this.currentStep = 1;
  // }

  checkIfClaimNumberIsOPD(claimNo: string): boolean{
    if(claimNo.charAt(0) == 'O' && claimNo.charAt(1) == 'P'){
      return true;
    }
    return false;
  }

  checkIfClaimNumberIsBlank(claimNo: string): boolean{
    if(claimNo == null || claimNo == undefined || claimNo == ''){
      return true;
    }
    return false;
  }

  previousStep() {
    if(this.currentStep === 4.5){
      this.currentStep = 4;
      return;
    }
    this.currentStep -= 1;
  }

  mobileNumberEntered() {
    if (/^s*[6-9][0-9]{9}s*$/.test(this.intimateClaim.MobileNumber) && this.intimateClaim.MobileNumber.length >= 10) {
      this.mobileNumberValid = true;
      this.invalidMobileNumberMsg = '';
    } else {
      this.mobileNumberValid = false;
      this.invalidMobileNumberMsg = 'Please enter valid Mobile number';
    }

    this.checkIsSubmitClaimTrue();
  }

  emailEntered() {
    if (/^s*\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+s*$/.test(this.intimateClaim.EmailID)) {
      this.emailValid = true;
      this.invalidEmailMsg = '';
    } else {
      this.emailValid = false;
      this.invalidEmailMsg = 'Please enter valid email';
    }

    this.checkIsSubmitClaimTrue();
  }

  checkIsSubmitClaimTrue() {
    if(this.isIntimatorSelf){
      if (this.emailValid && this.mobileNumberValid) {
        this.submitClaimDetails = true;
      } else {
        this.submitClaimDetails = false;
      }
    }else {
      if (this.intimatorNameValid && this.intimatorMobileNumberValid && this.selectedIntimator) {
        this.submitClaimDetails = true;
      } else {
        this.submitClaimDetails = false;
      }
    }
  }

  intimatorMobileNumberEntered(){
    if(/^s*[6-9][0-9]{9}s*$/.test(this.intimateClaim.IntimatorMobileNo) && this.intimateClaim.IntimatorMobileNo.length === 10){
      this.intimatorMobileNumberValid = true;
      this.invalidIntimatorMobileNumberMsg = '';
    }
    else{
      this.intimatorMobileNumberValid = false;
      this.invalidIntimatorMobileNumberMsg = 'Please enter valid Phone number';
    }
    this.checkIsSubmitClaimTrue();
  }
  intimatorNameEntered(){
    if(/^s*([a-zA-Z][a-zA-Z. ]*)s*$/.test(this.intimateClaim.IntimatorName) && this.intimateClaim.IntimatorName.length > 2){
      this.intimatorNameValid = true;
      this.invalidIntimatorNameMsg = '';
    }
    else{
      this.intimatorNameValid = false;
      this.invalidIntimatorNameMsg = 'Please enter valid Name';
    }
    this.checkIsSubmitClaimTrue();
  }
  getFinalDeclaration(value) {
    this.isFinalDeclared = value;
  }

  async submitDeclaration(claimNumber) {
    this.finalDeclarationData.claimNo = claimNumber;
    this.finalDeclarationData.claimantName = this.intimateClaim.IntimatorName;
    this.finalDeclarationData.uhid = this.intimateClaim.UHIDNumber;
    this.finalDeclarationData.insuredName = this.intimateClaim.InsuredName;
    this.finalDeclarationData.mobileNumber = this.intimateClaim.MobileNumber;
    this.finalDeclarationData.emailID = this.intimateClaim.EmailID;
    this.finalDeclarationData.patientName = this.intimateClaim.PatientName;
    this.finalDeclarationData.createdDate = moment().format();
    this.finalDeclarationData.age = this.intimateClaim.Age;
    this.finalDeclarationData.isDeclarationAccepted = this.isFinalDeclared === 'yes' ? true : false;

    await this.cpMemberService
      .submitFinalDeclaration(this.finalDeclarationData)
      .then(cj => {
        console.log('success');
      })
      .catch(cj => {
        console.log('error');
      });
  }
}
