import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { Ecard } from 'src/app/shared/models/ecard.model';
import { SelfMember } from 'src/app/cp-member/models/self-member.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { CPPolicyService } from '../../services/cp-policy.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CorporatePortalBaseComponent } from '../corporate-portal-base/corporate-portal-base.component';
import { EncruptioDecryptionService } from 'src/app/shared/services/encryption-decryption.service';
import { CPWellnessService } from 'src/app/cp-member/services/CPWellness.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EcardUhid } from 'src/app/cp-member/models/ecard-uhid.model';

import * as FileSaver from 'file-saver';
import { AbhaAddressId } from 'src/app/shared/enums/abha-address-id.enum';

@Component({
  selector: 'app-e-cards',
  templateUrl: './e-cards.component.html',
  styleUrls: ['./e-cards.component.scss'],
})
export class ECardsComponent extends CorporatePortalBaseComponent implements OnInit {
  data: Ecard;

  isHr: boolean;
  isMember: boolean;
  isHrView = false;

  members: SelfMember[] = [];
  ismemberLoaded = false;

  memberToEmail = new SelfMember();
  email: string;
  isEmailSent = false;
  invalidEmail = false;
  invalidEmailMsg: string;
  errorOccuredViaEmail = false;

  isEcardLoaded = true;

  searchingMember = false;

  errorMessage: any;

  empId = '';
  invalidEmpId = false;
  invalidEmpIdMsg: string;
  isEmployeeBelongsToPolicy = false;

  abhaId = '';
  invalidAbhaId = false;
  invalidAbhaIdMsg: string;

  abhaAddress = '';
  invalidAbhaAddress = false;
  invalidAbhaAddressMsg: string;

  constructor(
    injector: Injector,
    policyService: CPPolicyService,
    authService: AuthService,
    private encryptDecrypt: EncruptioDecryptionService,
    private cpMemberService: CPMemberService,
    private readonly alertService: AlertService,
    private cdr: ChangeDetectorRef
  ) {
    super(injector, policyService, authService);
  }

  async ngOnInit(): Promise<void> {
    // console.log('Get all current policy.');
    this.cpMemberService.isHrView.subscribe(v => {
      this.isHrView = v;
      this.empId = '';
      this.abhaId = '';
      this.abhaAddress = '';
    });

    this.ismemberLoaded = false;
    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.ismemberLoaded = false;
    this.searchingMember = true;
      this.onNamePolicyChanged();

      if (this.policyNumber !== undefined && this.isMember && !this.isHrView && this.isEmployeeBelongsToPolicy) {
        this.cpMemberService.memberDetails.subscribe(m => {
          if (m.length > 0) {
            var skiprun=false;
            this.members.forEach(element => {
                const index = m.findIndex(member => member.uhid === element.uhid);
                if(index>=0 && this.policyNumber!=m[index].policyNo)
                skiprun=true;
            });
            if(!skiprun){
            this.members = m;
            this.ismemberLoaded = false;
            this.members.forEach(element => {
              if (element.relationShipId === 'SELF' && element.isSelfCovered.toLowerCase() === 'false') {
                const index = this.members.findIndex(member => member === element);
                this.members.splice(index, 1);
              }
              element.memberSelected = false;
            });
            this.ismemberLoaded = true;
          }
          }
        });
      
      }
    });
  }

  onNamePolicyChanged() {
    this.ismemberLoaded = false;
    this.searchingMember = false;

    // console.log('Get employee allowed type/role.');
    this.isHr = this.cpMemberService.isHr;
    this.isMember = this.cpMemberService.isMember;

    // console.log('Does employee belongs to current policy.');
    this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;
    this.errorMessage = null;
  }

  downloadEcard() {
    const selectedMembers = this.members.filter(mem => mem.memberSelected);
    const uhids = selectedMembers.map(x => x.uhid);
    if (uhids.length > 0) {
      const EcardUhidData = new EcardUhid();
      EcardUhidData.policyNo = this.policyNumber;
      EcardUhidData.uHID = uhids;
      this.cpMemberService
        .downloadEcardUHIDWise(this.policyNumber, EcardUhidData)
        .then(x => {
          this.data = x;
          const blob = this.dataURItoBlob(x);
          FileSaver.saveAs(blob, `Ecard.pdf`);
          this.isEcardLoaded = true;
        })
        .catch(x => {
          console.log('error');
          this.alertService.error('Error occured!');
          this.isEcardLoaded = true;
        });
    }
  }
  inValidateMembers(event)
  {
    this.ismemberLoaded=false;
    this.cdr.detectChanges();
  }
  downloadEcardByPolicy() {
    this.cpMemberService
      .downloadEcardPolicyWise(this.policyNumber)
      .then(x => {
        this.data = x;
        const blob = this.dataURItoBlob(x);
        FileSaver.saveAs(blob, `Ecard.pdf`);
        this.isEcardLoaded = true;
      })
      .catch(x => {
        console.log('error');
        this.alertService.error('Error occured!');
        this.isEcardLoaded = true;
      });
  }

  showEcard(uhid) {
    
    if(this.ismemberLoaded){
      this.isEcardLoaded = false;
    this.cpMemberService
      .getECards(this.policyNumber, uhid)
      .then(x => {
        var userAgent = window.navigator.userAgent.toLowerCase();
        var safari = /safari/.test(userAgent);
        var ios = /iphone|ipod|ipad/.test(userAgent);

        if (ios) {
          if (safari) {
            // Safari
          } else if (safari) {
            // iOS webview
          };
        } else {
          if (userAgent.includes('wv')) {
            const URL = window.URL.createObjectURL(x);
            const aElement = document.createElement("a");
            aElement.setAttribute("href", URL);
            aElement.setAttribute("target", "_blank");
            aElement.setAttribute("download", "Ecard.pdf");
            aElement.click();
            FileSaver.saveAs(x, `Ecard.pdf`);
            window.open(URL, "_blank");
            window.location.href = URL;         
          } else {
            FileSaver.saveAs(x, `Ecard.pdf`);
          }
        };
        this.isEcardLoaded = true;
      })
      .catch(x => {
        console.log('error');
        this.alertService.error('Error occured!');
        this.isEcardLoaded = true;
      });
    }
  }
  dataURItoBlob(base64str) {
    const dataURI = 'data:application/pdf;base64, ' + encodeURI(base64str.file);
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  emailEcard(member) {
    this.isEmailSent = null;
    this.memberToEmail = member;
    this.email = member.emailID;
    this.invalidEmail = false;
    this.invalidEmailMsg = '';
  }

  emailInput(event) {
    const email = event.target.value;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i.test(email)) {
      this.invalidEmail = false;
      this.invalidEmailMsg = '';
    }
    else if (/^\w+([\.-]?\w+)*@arteras.engineering+$/i.test(email)) {
      this.invalidEmail = false;
      this.invalidEmailMsg = '';
    } 
    else {
      this.invalidEmail = true;
      this.invalidEmailMsg = 'Please enter valid Email Id';
    }
  }

  emailSubmit() {
    if(this.ismemberLoaded){
      const email = this.encryptDecrypt.encrypt(this.email, `${this.memberToEmail.uhid}KPngPNGyVN`);
  
    this.isEmailSent = false;
    this.cpMemberService
      .emailECards(this.policyNumber, this.memberToEmail.uhid, email)
      .then(x => {
        this.isEmailSent = true;
        this.errorOccuredViaEmail = false;
      })
      .catch(x => {
        console.log('error');
        this.errorOccuredViaEmail = true;
        this.isEmailSent = false;
      });
    }
  }

  employeeIdEntered(event) {
    const empId = event.target.value;
    this.abhaId = null;
    this.invalidAbhaId = false;
    this.invalidAbhaIdMsg = '';
    this.abhaAddress = null;
    this.invalidAbhaAddress = false;
    this.invalidAbhaAddressMsg = '';
    if (empId === '') {
      this.invalidEmpId = true;
      this.invalidEmpIdMsg = '';
    } else {
      if (/^\S(.*\S)?$/g.test(empId)) {
        this.invalidEmpId = false;
        this.invalidEmpIdMsg = '';
      } else {
        this.invalidEmpId = true;
        this.invalidEmpIdMsg = 'Please enter valid Employee Id';
      }
    }
    this.members = [];
    this.searchingMember = false;
  }

  abhaIdEntered(event) {
    const abhaId = event.target.value;
    this.empId = null;
    this.invalidEmpId = false;
    this.invalidEmpIdMsg = '';
    this.abhaAddress = null;
    this.invalidAbhaAddress = false;
    this.invalidAbhaAddressMsg = '';
    if (abhaId === '') {
      this.invalidAbhaId = true;
      this.invalidAbhaIdMsg = '';
    } else {
      if (/^[0-9]{14}$/g.test(abhaId)) {
        this.invalidAbhaId = false;
        this.invalidAbhaIdMsg = '';
      } else {
        this.invalidAbhaId = true;
        this.invalidAbhaIdMsg = 'Please enter valid Abha Id';
      }
    }
    this.members = [];
    this.searchingMember = false;
  }

  abhaAddressEntered(event) {
    const abhaAddress = event.target.value;
    this.empId = null;
    this.invalidEmpId = false;
    this.invalidEmpIdMsg = '';
    this.abhaId = null;
    this.invalidAbhaId = false;
    this.invalidAbhaIdMsg = '';
    if (abhaAddress === '') {
      this.invalidAbhaAddress = true;
      this.invalidAbhaAddressMsg = '';
    } else {
      if (/^[a-zA-Z]{1}[a-zA-Z0-9\.]*[a-zA-Z0-9]+@abdm$/g.test(abhaAddress) && abhaAddress.length >= 8 && abhaAddress.length <= 30) {
        this.invalidAbhaAddress = false;
        this.invalidAbhaAddressMsg = '';
      } else {
        this.invalidAbhaAddress = true;
        this.invalidAbhaAddressMsg = 'Please enter valid Abha Address';
      }
    }
    this.members = [];
    this.searchingMember = false;
  }

  async searchDetailsByEmpId() {
    this.searchingMember = true;
    this.ismemberLoaded = false;
    await this.cpMemberService
      .searchIntimateClaimsByEmpId(this.policyNumber, this.empId, this.abhaId, this.abhaAddress)
      .then(x => {
        this.members = x;
        this.members.forEach(element => {
          if (element.relationShipId === 'SELF' && element.isSelfCovered.toLowerCase() === 'false') {
            const index = this.members.findIndex(member => member === element);
            this.members.splice(index, 1);
          }
        });
        this.ismemberLoaded = true;
      })
      .catch(x => {
        this.errorMessage = x.error.errors[0].message;
        this.members = [];
        this.ismemberLoaded = true;
      }); 
  }
}
