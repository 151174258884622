import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { SelfMember } from 'src/app/cp-member/models/self-member.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CPPolicyService } from '../../services/cp-policy.service';
import { CorporatePortalBaseComponent } from '../corporate-portal-base/corporate-portal-base.component';
import { PACIBenefit } from 'src/app/cp-member/models/Intimate-claim/paci-benefit.model';
import { IntimateClaim } from 'src/app/cp-member/models/Intimate-claim/intimate-claim.model';
import { IntimateClaimDocument } from 'src/app/cp-member/models/Intimate-claim/intimate-claim-document.model';
import { IntimateClaimUploadDocuments } from 'src/app/cp-member/models/Intimate-claim/intimate-claim-upload-documents.model';
import { PACIIntimateClaim } from 'src/app/cp-member/models/Intimate-claim/paci-intimate-claim.model';
import { SubBenefit } from 'src/app/cp-member/models/Intimate-claim/sub-benefit.model';
import * as moment from 'moment';
import { IntimateSubmitClaim } from 'src/app/cp-member/models/Intimate-claim/intimate-submit-claim.model';
import { ClaimJourney } from 'src/app/cp-member/models/track-claim/claim-journey.model';
import { PACIProduct } from 'src/app/cp-member/models/Intimate-claim/paci-product.model';
import { AbhaAddressId } from 'src/app/shared/enums/abha-address-id.enum';

@Component({
  selector: 'app-paci',
  templateUrl: './paci.component.html',
  styleUrls: ['./paci.component.scss']
})
export class PaciComponent extends CorporatePortalBaseComponent implements OnInit {

  isHr: boolean;
  isHrView = false;
  isMember: boolean;
  searchingMember = false;
  isEmployeeBelongsToPolicy = false;
  ismemberLoaded = false;
  members: SelfMember[] = [];
  empId = null;
  abhaId: string = null;
  abhaAddress: string = null;

  invalidEmpId = false;
  invalidEmpIdMsg: string;

  //below variables are for HR search process
  invalidAbhaId = false;
  invalidAbhaIdMsg: string;

  invalidAbhaAddress = false;
  invalidAbhaAddressMsg: string;

  //below variables are for claim intimation process
  isAbhaIdValid = false;
  abhaIdInvalidMessage: string;

  isAbhaAddressValid = false;
  abhaAddressInvalidMessage: string;

  abhaIdAvailable: boolean = false;
  abhaAddressAvailable: boolean = false;

  errorR = [];
  error = null;

  firstRun = true;

  policyType = '';

  currentStep = 1;

  allPACIProducts: PACIProduct[] = [];
  selectedProduct: PACIProduct;
  mainProduct: string = null;
  allPACIBenefits: PACIBenefit[] = [];
  mainBenefit: string = null;
  selectedBenefit: PACIBenefit = new PACIBenefit();
  subBenefit: SubBenefit = null;
  subBenefitModel: string = null;
  isProductSelected: boolean = false;
  isMainBenefitSelected: boolean = false;
  isHospitalMandatory: boolean = true;

  subBenefitSelected: boolean = false;

  startDate = new Date();
  endDate = new Date();

  intimateClaim: PACIIntimateClaim;

  timeOfLoss = {hour: 12, minute:30};

  allMandatoryFieldsSelected: boolean = false;

  hospitalSearchResults: any[] = [];
  isSearchDone = false;

  hospitalNameValid: boolean = false;
  invalidHospitalNameMsg = '';
  doctorNameValid = false;
  invalidDoctorNameMsg = '';
  isHospitalChosen = false;

  claimDocuments: IntimateClaimDocument[] = [];
  isClaimDocumentsLoaded = false;

  fileListDocs = [];
  isSizeValid = false;
  uploadImageInvalidMsg = '';
  imageInvalid = false;
  intimateClaimDisableNextButton = false;
  isFinalDeclared: string;
  errorList = [];
  isIntimatorSelf = true;
  allRelationships: any[] = [];
  intimatorName:string = '';
  isSubmitClaim = false;
  selectedMemberForTrackClaim: any;
  fileArray: any = [];
  submitClaimData: IntimateSubmitClaim = new IntimateSubmitClaim();

  isClaimJourneyLoaded = false;
  claimJourney = new ClaimJourney();

  emailValid = false;
  emailNotNull = true;
  invalidEmailMsg = '';
  mobileNumberValid = false;
  mobileNumberNotNull = true;
  invalidMobileNumberMsg = '';
  submitClaimDetails = true;
  intimatorNameValid = false;
  intimatorMobileNumberValid = false;
  intimatorEmailValid = false;
  invalidIntimatorNameMsg: string = '';
  selectedIntimator: string = null;
  invalidIntimatorMobileNumberMsg: string = '';
  invalidOccupationErrorMsg: string = '';
  invalidIntimatorEmailMsg: string = '';

  isDateOfLossValid: boolean = false;
  isTimeOfLossValid: boolean = false; 
  isClaimAmountValid: boolean = false;
  isBriefDescValid: boolean = false;
  isOccupationValid: boolean = false;
  isDateOfIncidentValid: boolean = false;

  invalidBriefDescMsg: string = '';

  dateOfLossPlaceholder: string = 'Select Date of Loss';
  dateOfLossLabel: string = 'Date of Loss';
  timeOfLossLabel: string = 'Time of Loss';
  dateofIncidentLabel: string = 'Date of Incident';
  dateOfIncidentPlaceholder: string = 'Select Date of Incident';

  minTime: Date = new Date();
  maxTime: Date = new Date();

  isClaimNumberBlank: boolean = false;

  constructor(
    injector: Injector,
    policyService: CPPolicyService,
    authService: AuthService,
    private cpMemberService: CPMemberService,
    private cdr: ChangeDetectorRef
  ) { 
    super(injector, policyService, authService);
  }

  ngOnInit(): void {
    this.firstRun = true;
    this.policyType = window.sessionStorage.getItem('policyType');
    this.intimateClaim = new PACIIntimateClaim();
    this.cpMemberService.isHrView.subscribe(v => {
      this.isHrView = v;
      this.empId = null;
      if (!this.isHrView) {
        this.searchingMember = false;
      } else if (this.isHrView) {
        this.cpMemberService.hrDetails.subscribe(hr => {
          this.intimatorName = hr.name;
        });
      }
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.onNamePolicyChanged();
      if(this.policyNumber !== undefined && this.isMember && !this.isHrView && this.isEmployeeBelongsToPolicy){
        this.cpMemberService.getMemberFamily(this.policyNumber).then(m => {
          if (m.length > 0) {
            var skiprun = false;
             this.members.forEach(element => {
                const index = m.findIndex(member => member.uhid === element.uhid);
                if (index >= 0 && this.policyNumber != m[index].policyNo) {
                skiprun = true;
                }
              });
            if (!skiprun || this.firstRun) {
                this.members = m;
                this.firstRun = false;
                this.members.forEach(element => {
                  if (element.relationShipId.toUpperCase() === 'SELF') {
                    this.intimatorName = element.insuredName;
                  }
                  if (
                    element.relationShipId.toUpperCase() === 'SELF' &&
                    element.isSelfCovered.toLowerCase() === 'false'
                  ) {
                    const index = this.members.findIndex(member => member === element);
                    this.members.splice(index, 1);
                  }
                });
                this.ismemberLoaded = true;
              }
            }
        })
      }
    })
  }

  async searchIntimateClaimsByEmpId(){
    this.searchingMember = true;
    this.ismemberLoaded = false;
    await this.cpMemberService
      .searchIntimateClaimsByEmpId(this.policyNumber, this.empId, this.abhaId, this.abhaAddress)
      .then(x => {
        this.searchingMember = true;
        this.members = x;
        const role = this.authService.role;
        if (role === 'Broker' || role === 'SM') {
          this.members.forEach(element => {
            if (element.relationShipId === 'SELF') {
              // this.intimatorName = element.insuredName;
              // this.intimateClaim.IntimatorName = this.intimatorName;
            }
          });
        }

        this.members.forEach(element => {
          if (element.relationShipId === 'SELF' && element.isSelfCovered.toLowerCase() === 'false') {
            const index = this.members.findIndex(member => member === element);
            this.members.splice(index, 1);
          }
        });
        this.ismemberLoaded = true;
      })
      .catch(errorData => {
        console.log('error');
        this.errorR = errorData.error.errors;
        this.error = errorData.error.errors[0].message;
        this.members = [];
        this.ismemberLoaded = true;
      });
  }

  async searchByHospitalName() {

    if (this.intimateClaim.HospitalName.trim() !== '' && this.intimateClaim.HospitalName.length > 2) {
      await this.cpMemberService
        .searchHospital(this.intimateClaim.HospitalName, 0)
        .then(x => {
          this.hospitalSearchResults = x;
          this.isSearchDone = true;
        })
        .catch(x => {
          console.log('error');
        });
    }
  }

  HospitalNameEntered() {
    this.intimateClaim.HospitalId = null;
    this.intimateClaim.Address1 = null;
    this.intimateClaim.Address2 = null;
    this.intimateClaim.Address3 = null;
    this.intimateClaim.State = null;
    this.intimateClaim.City = null;
    this.intimateClaim.District = null;
    this.intimateClaim.Pincode = null;
    this.hospitalSearchResults = [];
    if (/^\s/.test(this.intimateClaim.HospitalName) || this.intimateClaim.HospitalName.length === 0) {
      this.hospitalNameValid = false;
      this.invalidHospitalNameMsg = 'Please enter valid Hospital name';
    } else {
      this.hospitalNameValid = true;
      this.invalidHospitalNameMsg = '';
    }
  }

  DoctorNameEntered() {
    if (/^s*([a-zA-Z][a-zA-Z. ]*)s*$/.test(this.intimateClaim.DoctorName) && this.intimateClaim.DoctorName.length > 2) {
      this.doctorNameValid = true;
      this.invalidDoctorNameMsg = '';
    } else {
      this.doctorNameValid = false;
      this.invalidDoctorNameMsg = 'Please enter valid Doctor name (Minimum 3 characters)';
    }
  }

  HospitalChosen(hospital) {
    this.isHospitalChosen = true;
    console.log(hospital);
    this.intimateClaim.HospitalId = hospital.hospitalId;
    this.intimateClaim.HospitalName = hospital.hospitalName;
    this.intimateClaim.Address1 = hospital.address1;
    this.intimateClaim.Address2 = hospital.address2;
    this.intimateClaim.Address3 = '';
    this.intimateClaim.State = hospital.state;
    this.intimateClaim.City = hospital.city;
    this.intimateClaim.District = hospital.district;
    this.intimateClaim.Pincode = hospital.pincode;
    this.hospitalSearchResults = [];
    this.hospitalSearchResults.push(hospital);
  }

  onNamePolicyChanged() {
    this.isHr = this.cpMemberService.isHr;
    this.isMember = this.cpMemberService.isMember;
    this.searchingMember = false;
    this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;
    this.errorR = [];
  }

  inValidateMembers(event) {
    this.ismemberLoaded = false;
    this.cdr.detectChanges();
  }

  setSearchByEmpId(event) {
    this.members = [];
    this.abhaId = null;
    this.invalidAbhaId = false;
    this.invalidAbhaIdMsg = '';
    this.abhaAddress = null;
    this.invalidAbhaAddress = false;
    this.invalidAbhaAddressMsg = '';
    this.searchingMember = false;
    this.ismemberLoaded = false;
    const empId = event.target.value;
    if (empId === '') {
      this.invalidEmpId = true;
      this.invalidEmpIdMsg = '';
    } else {
      if (/^\S(.*\S)?$/g.test(empId)) {
        this.invalidEmpId = false;
        this.invalidEmpIdMsg = '';
      } else {
        this.invalidEmpId = true;
        this.invalidEmpIdMsg = 'Please enter valid Employee Id';
      }
    }
  }

  setSearchByAbhaId(event){
    this.members = [];
    this.empId = null;
    this.invalidEmpId = false;
    this.invalidEmpIdMsg = '';
    this.abhaAddress = null;
    this.invalidAbhaAddress = false;
    this.invalidAbhaAddressMsg = '';
    this.searchingMember = false;
    this.ismemberLoaded = false;
    const abhaId = event.target.value;
    if (abhaId === '') {
      this.invalidAbhaId = true;
      this.invalidAbhaIdMsg = '';
    } else {
      if (/^[0-9]{14}$/g.test(abhaId)) {
        this.invalidAbhaId = false;
        this.invalidAbhaIdMsg = '';
      } else {
        this.invalidAbhaId = true;
        this.invalidAbhaIdMsg = 'Please enter valid Abha Id';
      }
    }
  }

  setSearchByAbhaAddress(event){
    this.members = [];
    this.empId = null;
    this.invalidEmpId = false;
    this.invalidEmpIdMsg = '';
    this.abhaId = null;
    this.invalidAbhaId = false;
    this.invalidAbhaIdMsg = '';
    this.searchingMember = false;
    this.ismemberLoaded = false;
    const abhaAddress = event.target.value;
    if (abhaAddress === '') {
      this.invalidAbhaAddress = true;
      this.invalidAbhaAddressMsg = '';
    } else {
      if (/^[a-zA-Z]{1}[a-zA-Z0-9\.]*[a-zA-Z0-9]+@abdm$/g.test(abhaAddress) && abhaAddress.length >= 8 && abhaAddress.length <= 30) {
        this.invalidAbhaAddress = false;
        this.invalidAbhaAddressMsg = '';
      } else {
        this.invalidAbhaAddress = true;
        this.invalidAbhaAddressMsg = 'Please enter valid Abha Address';
      }
    }
  }

  fillIntimateClaimData(member: SelfMember){
    this.intimateClaim.MobileNumber = member.mobileNo;
    this.intimateClaim.ReceivedMode = 'CorporatePortal';
    if(!this.isHrView) 
      this.intimateClaim.IntimatorRelationship = 'Self';
    else 
      this.intimateClaim.IntimatorRelationship = 'HR';
    this.intimateClaim.PatientMobileNo = member.mobileNo;
    this.intimateClaim.InsuredName = member.insuredName;
    this.intimateClaim.EmployeeID = member.memberIdEmpId;
    this.intimateClaim.EmailID = member.emailID;
    this.intimateClaim.Age = member.age;
    this.intimateClaim.RelationShip = member.relationShipId;
    this.intimateClaim.PatientName = member.insuredName;
    this.intimateClaim.UHIDNumber = member.uhid;
    this.intimateClaim.PatientUHID = member.uhid;
    this.intimateClaim.AbhaId = member.insuredABHAId;
    this.intimateClaim.AbhaAddress = member.insuredABHAAddress;
    this.intimateClaim.PolicyName = '';
    this.intimateClaim.PolicyNumber = this.currentPolicy.policyNumber;
    if(member.insuredABHAId == '' || member.insuredABHAId == undefined || member.insuredABHAId == null){
      this.abhaIdAvailable = false;
    }
    else{
      this.abhaIdAvailable = true;
    }
    if(member.insuredABHAAddress == '' || member.insuredABHAAddress == undefined || member.insuredABHAAddress == null){
      this.abhaAddressAvailable = false;
    }
    else{
      this.abhaAddressAvailable = true;
    }
  }

  async callIntimateClaim(member: SelfMember){
    this.resetIntimateClaimForm();
    this.selectedMemberForTrackClaim = member;
    this.intimateClaim = new PACIIntimateClaim();
    this.startDate = new Date(this.currentPolicy.riskStartDate);
    this.endDate = new Date();
    this.fillIntimateClaimData(member);
    this.cpMemberService.getPACIBenefits(this.policyNumber).then(res => {
      this.allPACIProducts = res;
      if(this.allPACIProducts.length == 1){
        this.mainProduct = this.allPACIProducts[0].productTypeId+"";
        this.isProductSelected = true;
        this.allPACIBenefits = [];
        this.allPACIBenefits = this.allPACIProducts[0].mainBenefitList
        this.selectedProduct = this.allPACIProducts[0];
      }
    })
  }

  mainProductSelected(event){
    this.isProductSelected = true;
    this.allPACIBenefits = [];
    this.allPACIProducts.forEach(element => {
      if(element.productTypeId == event.target.value){
        this.allPACIBenefits = element.mainBenefitList;
        this.selectedProduct = element;
      }
    })
  }
  mainBenefitSelected(event) {
    this.isMainBenefitSelected = true;
    this.subBenefit = null;
    this.subBenefitSelected = false;
    this.subBenefitModel = null
    this.allPACIBenefits.forEach(element => {
      if(element.mainBenefitId == event.target.value){
        this.selectedBenefit = element;
      }
    })
  }

  onSubBenefitSelected(event){
    this.subBenefitSelected = true;
    this.selectedBenefit.subBenefitList.forEach(element => {
      if(event.target.value == element.subBenefitId){
        this.subBenefit = element;
      }
    })
    console.log(this.subBenefit);
    console.log(this.selectedBenefit);
  }

  step1(){
    this.intimateClaim = new PACIIntimateClaim();
    this.intimateClaim.IntimationType = 'PA';
    this.fillIntimateClaimData(this.selectedMemberForTrackClaim);
    this.currentStep = 2;
    this.mainBenefit = null;
    this.subBenefit = null;
    this.subBenefitModel = null;
    if(this.allPACIProducts.length == 1){
      this.mainProduct = this.allPACIProducts[0].productTypeId+"";
      this.isProductSelected = true;
      this.allPACIBenefits = [];
      this.allPACIBenefits = this.allPACIProducts[0].mainBenefitList
      this.selectedProduct = this.allPACIProducts[0];
    }
  }

  step2(){
    this.resetMandatoryFields();
    this.setLabelsAndMandatoryFields();
    this.currentStep = 3;
    console.log(this.intimateClaim);
    if(this.intimateClaim.LossDate !== undefined){
      this.dateOfLossChanged();
    }
    if(this.intimateClaim.ClaimAmount !== undefined){
      this.claimAmountEntered();
    }
    if(this.intimateClaim.LossDescription !== undefined){
      this.briefDescEntered();
    }
    if(this.intimateClaim.IncidentDate !== undefined){
      this.dateOfIncidentChanged();
    }
    if(this.timeOfLoss !== undefined){
      this.timeOfLossEntered();
    }
    if(this.intimateClaim.BenefitInsuredOccupation !== undefined){
      this.occupationEntered();
    }
    if(this.intimateClaim.AbhaId != undefined && this.intimateClaim.AbhaId != null && this.intimateClaim.AbhaId != ''){
      this.abhaIdEnteredForIntimation();
    }
    if(this.intimateClaim.AbhaAddress != undefined && this.intimateClaim.AbhaAddress != null && this.intimateClaim.AbhaAddress != ''){
      this.abhaAddressEnteredForIntimation();
    }
  }

  abhaIdEnteredForIntimation(){
    if(this.intimateClaim.AbhaId == null || this.intimateClaim.AbhaId == undefined || this.intimateClaim.AbhaId == ''){
      this.abhaIdAvailable = false;
    }
    else{
      this.abhaIdAvailable = true;
    }
    if(/^[0-9]{14}$/g.test(this.intimateClaim.AbhaId)){
      this.isAbhaIdValid = true;
      this.abhaIdInvalidMessage = '';
    }
    else{
      this.isAbhaIdValid = false;
      this.abhaIdInvalidMessage = 'Please enter valid Abha Id';
    }
  }

  abhaAddressEnteredForIntimation(){
    if(this.intimateClaim.AbhaAddress == null || this.intimateClaim.AbhaAddress == undefined || this.intimateClaim.AbhaAddress == ''){
      this.abhaAddressAvailable = false;
    }
    else{
      this.abhaAddressAvailable = true;
    }
    if(/^[a-zA-Z]{1}[a-zA-Z0-9\.]*[a-zA-Z0-9]+@abdm$/g.test(this.intimateClaim.AbhaAddress) && this.intimateClaim.AbhaAddress.length >= 8 && this.intimateClaim.AbhaAddress.length <= 30){
      this.isAbhaAddressValid = true;
      this.abhaAddressInvalidMessage = '';
    }
    else{
      this.isAbhaAddressValid = false;
      this.abhaAddressInvalidMessage = 'Please enter valid Abha Address';
    }
  }

  step3(){
    this.currentStep = 4;
    console.log(this.subBenefit);
    if(this.subBenefit.subBenefitDescription === "Loss Of Employment"){
      this.isHospitalMandatory = false;
    }
    else{
      this.isHospitalMandatory = true;
    }
  }

  step4(){
    this.currentStep = 5;
    this.cpMemberService
      .getPACIIntimateClaimDocuments(this.selectedProduct.productTypeId, this.selectedBenefit.mainBenefitId, this.subBenefit.subBenefitId)
      // .getIntimateClaimDocuments("CLOPD")
      .then(x => {
        this.claimDocuments = x;
        this.isClaimDocumentsLoaded = true;
        this.claimDocuments.sort(claimDocument => {
          return claimDocument.isMandatory === false ? 0 : claimDocument ? -1 : 1;
        });
        this.claimDocuments.forEach(element => {
          if (element.isMandatory === true) {
            const index = this.intimateClaim.DocumentList.findIndex(document => document.DocTypeId === element.documentId);
            if (index === -1) {
              this.intimateClaimDisableNextButton = true;
              return;
            } else {
              this.intimateClaimDisableNextButton = false;
            }
          }
        });
      })
      .catch(errorData => {
        console.log('error');
      });
  }

  step5(){
    this.currentStep = 6;
    console.log(this.intimateClaim.DocumentList);
  }

  step6(){
    this.currentStep = 7;
  }

  step7(){
    this.currentStep = 8;
    if (
      this.intimateClaim.EmailID === '' ||
      this.intimateClaim.EmailID === undefined ||
      this.intimateClaim.EmailID === null
    ) {
      this.emailValid = false;
      this.invalidEmailMsg = '';
      this.emailNotNull = false;
    } else {
      this.emailEntered();
    }

    if (
      this.intimateClaim.MobileNumber === '' ||
      this.intimateClaim.MobileNumber === undefined ||
      this.intimateClaim.MobileNumber === null
    ) {
      this.mobileNumberValid = false;
      this.invalidMobileNumberMsg = '';
      this.mobileNumberNotNull = false;
    } else {
      this.mobileNumberEntered();
    }
    this.checkIsSubmitClaimTrue();
  }

  step8(){
    if(this.intimateClaim.IntimatorRelationship!=='HR' && this.selectedIntimator !=='')
      this.intimateClaim.IntimatorRelationship = this.selectedIntimator;
    
    if(this.isIntimatorSelf){
      this.intimateClaim.IntimatorName = this.intimatorName;
      this.intimateClaim.IntimatorMobileNo = this.intimateClaim.MobileNumber;
      this.intimateClaim.IntimatorRelationship = 'SELF';
    }
    if(!this.isIntimatorSelf && this.intimateClaim.MobileNumber === "") {
      this.intimateClaim.MobileNumber = this.intimateClaim.IntimatorMobileNo;
    }
    this.intimateClaim.LossTime = this.timeOfLoss.hour +":"+this.timeOfLoss.minute;
    this.intimateClaim.ProductTypeId = this.selectedProduct.productTypeId;
    this.intimateClaim.ProductType = this.selectedProduct.productTypeDescription;
    this.intimateClaim.TypeofLossID = this.selectedBenefit.mainBenefitId;
    this.intimateClaim.TypeofLoss = this.selectedBenefit.mainBenefitDescription;
    this.intimateClaim.BenefitTypeID = this.subBenefit.subBenefitId;
    this.intimateClaim.BenefitType = this.subBenefit.subBenefitDescription;
    this.intimateClaim.PolicyStartDate = moment(this.intimateClaim.PolicyStartDate).format('DD MMMM YYYY');
    this.intimateClaim.PolicyEndDate = moment(this.intimateClaim.PolicyEndDate).format('DD MMMM YYYY');
    if(this.intimateClaim.City == null || this.intimateClaim.City == ''){
      this.intimateClaim.City = 'Mumbai';
    }
    this.intimateClaim.DiagnosisDetails = this.intimateClaim.LossDescription;
    if ('AdmissionDate' in this.intimateClaim) {
      this.intimateClaim.AdmissionDate = moment(this.intimateClaim.AdmissionDate).format('YYYY-MM-DD');
    }

    if (
      'DischargeDate' in this.intimateClaim &&
      this.intimateClaim.DischargeDate !== null &&
      this.intimateClaim.DischargeDate !== undefined &&
      this.intimateClaim.DischargeDate !== ''
    ) {
      this.intimateClaim.DischargeDate = moment(this.intimateClaim.DischargeDate).format('YYYY-MM-DD');
    }

    if (
      'LossDate' in this.intimateClaim &&
      this.intimateClaim.LossDate !== null &&
      this.intimateClaim.LossDate !== undefined &&
      this.intimateClaim.LossDate !== ''
    ) {
      this.intimateClaim.LossDate = moment(this.intimateClaim.LossDate).format('YYYY-MM-DD');
      this.intimateClaim.AdmissionDate = this.intimateClaim.LossDate;
    }

    if (
      'IncidentDate' in this.intimateClaim &&
      this.intimateClaim.IncidentDate !== null &&
      this.intimateClaim.IncidentDate !== undefined &&
      this.intimateClaim.IncidentDate !== ''
    ) {
      this.intimateClaim.IncidentDate = moment(this.intimateClaim.IncidentDate).format('DD-MMM-YYYY');
    }

    this.fileArray = [];
    for (let index = 0; index < this.intimateClaim.DocumentList.length; index++) {
      this.fileArray.push(this.intimateClaim.DocumentList[index].OriginalFile);
    }

    const data = new FormData();
    // this.fileArray.forEach(file => {
    //   data.append('File', file);
    // });
    data.append('File', this.fileArray[0]);
    const allIntimateClaimDocument: IntimateClaimUploadDocuments[] = this.intimateClaim.DocumentList;
    this.intimateClaim.DocumentList = [];
    this.intimateClaim.DocumentList.push(allIntimateClaimDocument[0]);

    data.append('Json', JSON.stringify(this.intimateClaim));
    this.cpMemberService
      .submitPACIClaims(data)
      .then(async x => {
        // the first doc is removed because that document is already submitted in first request
        if(this.checkIfClaimNumberIsBlank(x.claimNumber)){
          this.isClaimNumberBlank = true;
        }
        else{
          this.isClaimNumberBlank = false;
        }
        allIntimateClaimDocument.splice(0, 1);
        this.fileArray.splice(0, 1);
        const promises = [];
        for(let i=0; i<allIntimateClaimDocument.length; i++){
            const documentToUpload: IntimateClaimUploadDocuments[] = [];
            documentToUpload.push(allIntimateClaimDocument[i]);
            const data = new FormData();
            const json = {
              claimNo: x.claimNumber,
              documentList: documentToUpload,
            }
            data.append('File', this.fileArray[i]);
            data.append('Json', JSON.stringify(json));
            promises.push(this.cpMemberService.uploadDocument(data));
        }
        Promise.all(promises).then(result => {
          console.log(result+"all promises are resolved");
        })
        this.submitClaimData = x;
        this.currentStep = 9;
        this.isSubmitClaim = false;
        this.isClaimJourneyLoaded = false;
        // this.submitDeclaration(x.claimNumber);
        await this.cpMemberService
          .getClaimsJournery(x.claimNumber)
          .then(cj => {
            this.claimJourney = cj;
            this.isClaimJourneyLoaded = true;
            let lastStage = 11;
            for (let i = 1; i < 11; i++) {
              const v = this.claimJourney['s' + i]['isCurrentState'];
              if (i > lastStage) {
                this.claimJourney['s' + i] = null;
              }

              if (v === true) {
                lastStage = i;
              }
            }
          })
          .catch(cj => {
            console.log('error');
          });
      })
      .catch(errorData => {
        console.log('error');
        this.errorList = errorData.error.errors;
        this.isSubmitClaim = false;
      });
    
    console.log(this.intimateClaim);
  }

  previousStep(){
    this.currentStep -= 1;
  }

  checkIfClaimNumberIsBlank(claimNo: string): boolean{
    if(claimNo == null || claimNo == undefined || claimNo == ''){
      return true;
    }
    return false;
  }

  resetMandatoryFields(){
    this.isTimeOfLossValid = false; 
    this.isDateOfLossValid = false;
    this.isClaimAmountValid = false;
    this.isBriefDescValid = false;
    this.isOccupationValid = false;
    this.isDateOfIncidentValid = false; 

    this.abhaIdInvalidMessage = '';
    this.abhaAddressInvalidMessage = '';
    this.isAbhaIdValid = false;
    this.isAbhaAddressValid = false;
  }

  setLabelsAndMandatoryFields(){
    if(this.subBenefit.subBenefitDescription == 'Critical illness'){
      this.dateOfLossLabel = "Date of Admission";
      this.dateOfLossPlaceholder = "Select Date of Admission";
      this.timeOfLossLabel = "Time of Admission";
      this.dateofIncidentLabel = "Date of First Consultation"
      this.dateOfIncidentPlaceholder = "Select Date of First Consultation";
    }
    if(this.subBenefit.subBenefitDescription == 'Accidental Death'){
      this.dateOfLossLabel = "Date of Loss";
      this.dateOfLossPlaceholder = "Select Date of Loss";
      this.timeOfLossLabel = "Time of Loss";
      this.dateofIncidentLabel = "Date of Death";
      this.dateOfIncidentPlaceholder = "Select Date of Death";
    }
    if(this.subBenefit.subBenefitDescription != 'Accidental Death' && this.subBenefit.subBenefitDescription != 'Critical illness'){
      this.dateOfLossLabel = "Date of Loss";
      this.dateOfLossPlaceholder = "Select Date of Loss";
      this.timeOfLossLabel = "Time of Loss";
      this.dateofIncidentLabel = "Date of Incident"
      this.dateOfIncidentPlaceholder = "Select Date of Incident";
    }
  }

  getFinalDeclaration(value) {
    this.isFinalDeclared = value;
  }

  removeFileFromIntimateDocuments(documentArray, documentId) {
    return documentArray.filter(el => el.DocTypeId !== documentId);
  }

  removeFromFileListDocs(documentArray, documentId) {
    return documentArray.filter(el => el.documentID !== documentId);
  }

  fileSelectionEvent(event, doc) {
    const files = event.target.files;
    console.log(typeof files[0]);
    const fileNameArrayInUI = [];
    if (files.length > 0) {
      if (this.intimateClaim.DocumentList.length !== 0) {
        this.intimateClaim.DocumentList = this.removeFileFromIntimateDocuments(
          this.intimateClaim.DocumentList,
          doc.documentId
        );
        this.fileListDocs = this.removeFromFileListDocs(this.fileListDocs, doc.documentId);
      }

      for (const file of files) {
        const File: IntimateClaimUploadDocuments = {
          FileName: file.name,
          FileBytes: file,
          DocTypeId: doc.documentId,
          OriginalFile: file,
          Description: doc.description
        };
        this.intimateClaim.DocumentList.push(File);

        let fileNameInUi = file.name;
        if (fileNameInUi.length > 30) {
          fileNameInUi = fileNameInUi.slice(0, 40) + '...';
        }
        fileNameArrayInUI.push(fileNameInUi);
        let multipleFilesNameInUi = '';
        multipleFilesNameInUi = fileNameArrayInUI.join(', ');

        (<HTMLInputElement>document.getElementById(doc.documentId + 'para')).innerText = multipleFilesNameInUi;
        document.getElementById(doc.documentId + 'para').style.display = 'block';
        document.getElementById(doc.documentId + 'delete').style.display = 'block';
      }

      for (const file of files) {
        const size = file.size;
        const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
        if (!fileExtensionArr.includes(ext.toLowerCase())) {
          this.isSizeValid = false;
          this.uploadImageInvalidMsg = file.name + ' is invalid. Upload Valid Format File(Maximum 5 Mb)';
          (<HTMLInputElement>(
            document.getElementById(doc.documentId + 'invalid')
          )).innerText = this.uploadImageInvalidMsg;
          document.getElementById(doc.documentId + 'invalid').style.display = 'block';
          break;
        } else {
          this.isSizeValid = true;
          this.uploadImageInvalidMsg = '';
          (<HTMLInputElement>(
            document.getElementById(doc.documentId + 'invalid')
          )).innerText = this.uploadImageInvalidMsg;
          document.getElementById(doc.documentId + 'invalid').style.display = 'none';
        }
        if (this.isSizeValid) {
          if (size > 5000000) {
            this.isSizeValid = false;
            this.uploadImageInvalidMsg = file.name + ' is invalid. Maximum file size must be 5 Mb.';
            (<HTMLInputElement>(
              document.getElementById(doc.documentId + 'invalid')
            )).innerText = this.uploadImageInvalidMsg;
            document.getElementById(doc.documentId + 'invalid').style.display = 'block';
            break;
          } else {
            this.isSizeValid = true;
            this.uploadImageInvalidMsg = '';
            (<HTMLInputElement>(
              document.getElementById(doc.documentId + 'invalid')
            )).innerText = this.uploadImageInvalidMsg;
            document.getElementById(doc.documentId + 'invalid').style.display = 'none';
          }
        }

        document.getElementById(doc.documentId + 'delete').style.display = 'block';
        document.getElementById(doc.documentId + 'para').style.display = 'block';
      }

      const FileCopyForValidation = {
        documentID: doc.documentId,
        isImageValid: this.isSizeValid,
      };

      this.fileListDocs.push(FileCopyForValidation);
      for (let i = 0; i < this.fileListDocs.length; i++) {
        if (this.fileListDocs[i].isImageValid === false) {
          this.imageInvalid = true;
          break;
        } else {
          this.imageInvalid = false;
        }
      }

      // this.claimDocuments.forEach(element => {
      //   if (element.isMandatory === true) {
      //     const index = this.intimateClaim.DocumentList.findIndex(
      //       document => document.DocTypeId === element.documentId
      //     );
      //     if (index === -1 ) {
      //       this.intimateClaimDisableNextButton = true;
      //       return;
      //     } else {
      //       this.intimateClaimDisableNextButton = false;
      //     }
      //   }
      // });
      for(let i=0; i<this.claimDocuments.length; i++){
        const element = this.claimDocuments[i];
        if(element.isMandatory === true){
          const index = this.intimateClaim.DocumentList.findIndex(
            doc => doc.DocTypeId === element.documentId
          );
          if(index === -1){
            this.intimateClaimDisableNextButton = true;
            break;
          } 
          this.intimateClaimDisableNextButton = false;
        }
      }
      // this.checkInitiateClaimButton();
      this.convertFilesArrayToBase64Array();
    }
    console.log(this.intimateClaimDisableNextButton, 'intimateclaimnextbutton');
    console.log(this.imageInvalid, 'Image invaliddd');
    console.log(this.fileListDocs, 'filelistdocs');
    console.log(this.intimateClaim.DocumentList, 'documentlist');
  }

  deleteFile(doc) {
    document.getElementById(doc.documentId + 'invalid').style.display = 'none';
    document.getElementById(doc.documentId + 'delete').style.display = 'none';
    document.getElementById(doc.documentId + 'para').style.display = 'none';
    (<HTMLInputElement>document.getElementById(doc.documentId + 'para')).innerText = '';
    (<HTMLInputElement>document.getElementById(doc.documentId + 'invalid')).innerText = '';
    this.intimateClaim.DocumentList = this.removeFileFromIntimateDocuments(
      this.intimateClaim.DocumentList,
      doc.documentId
    );
    this.fileListDocs = this.removeFromFileListDocs(this.fileListDocs, doc.documentId);
    console.log(this.intimateClaim.DocumentList);
    console.log(this.fileListDocs);

    //this code has a bug, because foreach doesnt break like normal for loops, so the return statement doesnt work
    // this.claimDocuments.forEach(element => {
    //   if (element.isMandatory === true) {
    //     const index = this.intimateClaim.DocumentList.findIndex(document => document.DocTypeId === element.documentId);
    //     if (index === -1) {
    //       this.intimateClaimDisableNextButton = true;
    //       return;
    //     } else {
    //       this.intimateClaimDisableNextButton = false;
    //     }
    //   }
    // });

    for(let i=0; i<this.claimDocuments.length; i++){
      const element = this.claimDocuments[i];
      if (element.isMandatory === true) {
        const index = this.intimateClaim.DocumentList.findIndex(document => document.DocTypeId === element.documentId);
        if (index === -1) {
          this.intimateClaimDisableNextButton = true;
          return;
        } else {
          this.intimateClaimDisableNextButton = false;
        }
      }
    }
    console.log(this.intimateClaimDisableNextButton, 'Intimate Claim Disable Button');

    //commented for PACI claim
    // for (let i = 0; i < this.fileListDocs.length; i++) {
    //   if (this.fileListDocs[i].isImageValid === false) {
    //     if (this.claimType != 'Reimbursement OPD') {
    //              this.imageInvalid = true;
    //     }
    //    break;
    //   } else {
    //     this.imageInvalid = false;
    //   }
    // }
    console.log(this.intimateClaimDisableNextButton, 'intimateclaimnextbutton');
    console.log(this.imageInvalid, 'Image invaliddd');
    console.log(this.fileListDocs, 'filelistdocs');
    console.log(this.intimateClaim.DocumentList, 'documentlist');
  }

  convertFilesArrayToBase64Array() {
    this.intimateClaim.DocumentList.forEach(element => {
      if (element.FileBytes instanceof File) {
        this.convertFiletoBase64(element.FileBytes).then(FileByte => {
          const filebyteInBase64 = String(FileByte).split('base64,')[1];
          const index1 = this.intimateClaim.DocumentList.findIndex(document => document === element);
          if (index1 !== -1) {
            this.intimateClaim.DocumentList[index1].FileBytes = filebyteInBase64;
          }
        });
      }
    });
  }

  toggleIntimator(flag: boolean){
    this.isIntimatorSelf = flag;
    this.checkIsSubmitClaimTrue();
    if(this.allRelationships.length==0)
      this.getAllRelationships();
  }

  getAllRelationships(){
    console.log(this.currentPolicy);
    this.cpMemberService.getAllRelationships().then(res => {
      this.allRelationships = res;
    })
  }

  checkIsSubmitClaimTrue() {
    if(this.isIntimatorSelf){
      if (this.emailValid && this.mobileNumberValid) {
        this.submitClaimDetails = true;
      } else {
        this.submitClaimDetails = false;
      }
    }else {
      if (this.intimatorNameValid && this.intimatorMobileNumberValid && this.selectedIntimator && this.intimatorEmailValid) {
        this.submitClaimDetails = true;
      } else {
        this.submitClaimDetails = false;
      }
    }
  }

  emailEntered() {
    if (/^s*\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+s*$/.test(this.intimateClaim.EmailID)) {
      this.emailValid = true;
      this.invalidEmailMsg = '';
    } else {
      this.emailValid = false;
      this.invalidEmailMsg = 'Please enter valid email';
    }

    this.checkIsSubmitClaimTrue();
  }

  mobileNumberEntered() {
    if (/^s*[6-9][0-9]{9}s*$/.test(this.intimateClaim.MobileNumber) && this.intimateClaim.MobileNumber.length >= 10) {
      this.mobileNumberValid = true;
      this.invalidMobileNumberMsg = '';
    } else {
      this.mobileNumberValid = false;
      this.invalidMobileNumberMsg = 'Please enter valid Mobile number';
    }

    this.checkIsSubmitClaimTrue();
  }

  intimatorNameEntered(){
    if(/^s*([a-zA-Z][a-zA-Z. ]*)s*$/.test(this.intimateClaim.IntimatorName) && this.intimateClaim.IntimatorName.length > 2){
      this.intimatorNameValid = true;
      this.invalidIntimatorNameMsg = '';
    }
    else{
      this.intimatorNameValid = false;
      this.invalidIntimatorNameMsg = 'Please enter valid Name';
    }
    this.checkIsSubmitClaimTrue();
  }

  intimatorMobileNumberEntered(){
    if(/^s*[6-9][0-9]{9}s*$/.test(this.intimateClaim.IntimatorMobileNo) && this.intimateClaim.IntimatorMobileNo.length === 10){
      this.intimatorMobileNumberValid = true;
      this.invalidIntimatorMobileNumberMsg = '';
    }
    else{
      this.intimatorMobileNumberValid = false;
      this.invalidIntimatorMobileNumberMsg = 'Please enter valid Phone number';
    }
    this.checkIsSubmitClaimTrue();
  }

  intimatorEmailEntered() {
    if (/^s*\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+s*$/.test(this.intimateClaim.EmailID)) {
      this.intimatorEmailValid = true;
      this.invalidIntimatorEmailMsg = '';
    } else {
      this.intimatorEmailValid = false;
      this.invalidIntimatorEmailMsg = 'Please enter valid email';
    }

    this.checkIsSubmitClaimTrue();
  }

  dateOfLossChanged() {
    if(this.intimateClaim.LossDate !== '' && this.intimateClaim.LossDate !== undefined){
      this.isDateOfLossValid = true;
    }
    else{
      this.isDateOfLossValid = false;
    }
  }

  claimAmountEntered() {
    if(this.intimateClaim.ClaimAmount > 0){
      this.isClaimAmountValid = true;
    }
    else{
      this.isClaimAmountValid = false;
    }
  }

  briefDescEntered() {
    // need to confirm more validations on this
    if(this.intimateClaim.LossDescription !== '' && this.intimateClaim.LossDescription.length > 9 && this.intimateClaim.LossDescription.length <= 500){
      this.isBriefDescValid = true;
      this.invalidBriefDescMsg = '';
    }
    else{
      this.isBriefDescValid = false;
      this.invalidBriefDescMsg = 'Loss Description should be of minimum 10 and maximum 500 characters.';
    }
  }

  occupationEntered() {
    if(this.intimateClaim.BenefitInsuredOccupation !== '' && this.intimateClaim.BenefitInsuredOccupation.length >= 2 && this.intimateClaim.BenefitInsuredOccupation.length <= 30){
      this.isOccupationValid = true;
    }
    else{
      this.isOccupationValid = false;
      this.invalidOccupationErrorMsg = 'Occupation should be minimum 2 and maximum 30 characters long';
    }
  }

  dateOfIncidentChanged() {
    if(this.intimateClaim.IncidentDate !== ''){
      this.isDateOfIncidentValid = true;
    }
    else{
      this.isDateOfIncidentValid = false;
    }
  }

  timeOfLossEntered(){
    if(this.timeOfLoss !== null && this.timeOfLoss !== undefined){
      this.isTimeOfLossValid = true;
    }
    else{
      this.isTimeOfLossValid = false;
    }
  }

  resetIntimateClaimForm(){
    this.isHospitalMandatory = true;
    this.isProductSelected = false;
    this.isMainBenefitSelected = false;
    this.allPACIBenefits = [];
    this.selectedBenefit = new PACIBenefit();
    this.subBenefitSelected = false;
    this.mainProduct = null;
    this.mainBenefit = null;
    // this.causeOfHospitalizationValid = false;
    // this.invalidCauseMsg = '';
    // this.invalidCategoryMsg='';
    // this.claimCategoryValid = false;
    this.doctorNameValid = false;
    this.invalidDoctorNameMsg = '';
    this.hospitalNameValid = false;
    this.invalidHospitalNameMsg = '';
    // this.isAllMandatoryFilesUploaded = false;
    this.intimateClaimDisableNextButton = false;
    this.isIntimatorSelf = true;
    this.intimatorNameValid = false;
    this.intimatorMobileNumberValid = false;
    this.intimatorEmailValid = false;
    this.invalidIntimatorMobileNumberMsg = '';
    this.invalidIntimatorEmailMsg = '';
    this.invalidOccupationErrorMsg = '';
    this.invalidBriefDescMsg = '';
    this.invalidIntimatorNameMsg = '';
    this.selectedIntimator = null;
    // this.selectedMemberForTrackClaim = {};
    // this.submitClaimData = new IntimateSubmitClaim();

    this.claimDocuments = [];
    this.isClaimDocumentsLoaded = false;

    this.hospitalSearchResults = [];
    this.isSearchDone = false;

    // this.claimType = '';

    this.currentStep = 1;
    this.isFinalDeclared = undefined;
    this.submitClaimData = new IntimateSubmitClaim();

    this.abhaIdAvailable = false;
    this.abhaAddressAvailable = false;
  }

  async convertFiletoBase64(file) {
    const result_base64 = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = e => resolve(fileReader.result);
      fileReader.onerror = e => reject(e);
    });
    return result_base64;
  }

  // async submitDeclaration(claimNumber) {
  //   this.finalDeclarationData.claimNo = claimNumber;
  //   this.finalDeclarationData.claimantName = this.intimateClaim.IntimatorName;
  //   this.finalDeclarationData.uhid = this.intimateClaim.UHIDNumber;
  //   this.finalDeclarationData.insuredName = this.intimateClaim.InsuredName;
  //   this.finalDeclarationData.mobileNumber = this.intimateClaim.MobileNumber;
  //   this.finalDeclarationData.emailID = this.intimateClaim.EmailID;
  //   this.finalDeclarationData.patientName = this.intimateClaim.PatientName;
  //   this.finalDeclarationData.createdDate = moment().format();
  //   this.finalDeclarationData.age = this.intimateClaim.Age;
  //   this.finalDeclarationData.isDeclarationAccepted = this.isFinalDeclared === 'yes' ? true : false;

  //   await this.cpMemberService
  //     .submitFinalDeclaration(this.finalDeclarationData)
  //     .then(cj => {
  //       console.log('success');
  //     })
  //     .catch(cj => {
  //       console.log('error');
  //     });
  // }
}
