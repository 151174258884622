<div class="e-card-wrapper pb-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-6" *ngIf="(isHr && isHrView && role === 'CPHR') || role === 'Broker' || role === 'SM'">
        <div class="card card-style-1">
          <div class="card-body">
            <div class="card-title row justify-content-between align-items-center">
              <h4 class="m-0 header-title">View Claim Details Under Policy</h4>
            </div>
            <div class="row pt-3">
              <div class="col-12">
                <div class="px-4">
                  <app-policies-dropdown heading="Search E-Card" (onChange)="inValidateMembers($event)" class="w-100"></app-policies-dropdown>
                  <div class="claim-search-box" *ngIf="currentPolicy.viewECard">
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="w-100">
                          <mat-label>Employee ID</mat-label>
                          <input
                            matInput
                            placeholder="Employee ID"
                            [(ngModel)]="empId"
                            (input)="employeeIdEntered($event)"
                          />
                        </mat-form-field>
                        <p *ngIf="invalidEmpId" class="error-msg">
                          <small>{{ invalidEmpIdMsg }}</small>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="w-100">
                          <mat-label>Abha ID</mat-label>
                          <input
                            matInput
                            placeholder="Abha ID "
                            [(ngModel)]="abhaId"
                            (input)="abhaIdEntered($event)"
                            [maxLength]="14"
                          />
                        </mat-form-field>
                        <p *ngIf="invalidAbhaId" class="error-msg">
                          <small>{{ invalidAbhaIdMsg }}</small>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="w-100">
                          <mat-label>Abha Address</mat-label>
                          <input
                            matInput
                            placeholder="Abha Address"
                            [(ngModel)]="abhaAddress"
                            (input)="abhaAddressEntered($event)"
                            [maxLength]="30"
                          />
                        </mat-form-field>
                        <p *ngIf="invalidAbhaAddress" class="error-msg">
                          <small>{{ invalidAbhaAddressMsg }}</small>
                        </p>
                      </div>
                    </div>
                    <div class="form-action-wrapper">
                      <button
                        type="submit"
                        class="btn px-4 btn-primary"
                        [disabled]="invalidEmpId || invalidAbhaId || invalidAbhaAddress || (empId == '' && abhaId == '' && abhaAddress == '')"
                        (click)="searchDetailsByEmpId()"
                      >
                        Search Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      <!-- This div will be displayed in member view -->

        <div class="col-xs-12 col-md-6 col-lg-6">
          <div class="card card-style-1" *ngIf="currentPolicy.viewECard &&
          (isMember && isEmployeeBelongsToPolicy && !isHrView) ||
          ((isHrView || role === 'Broker' || role === 'SM') && searchingMember)
        ">
            <div class="card-body">
              <div class="card-title row justify-content-between align-items-center">
                <h4 class="m-0 header-title">Insured Details</h4>
              </div>
              <app-policies-dropdown [hidePolicyDropdown]="!(!isHrView && isMember)"  (onChange)="inValidateMembers($event)" heading="View E-Card" class="w-100">
              </app-policies-dropdown>

              <div class="row">
                <div class="col-12">
                  <div class="claims-cont">
                    <ul class="list-unstyled shimmer-wrapper" *ngIf="!ismemberLoaded">
                      <li class="shimmer-row">
                        <div class="image-shimmer"></div>
                        <div class="shimmer"></div>
                      </li>
                      <li class="shimmer-row">
                        <div class="image-shimmer"></div>
                        <div class="shimmer"></div>
                      </li> 
                      <li class="shimmer-row">
                        <div class="image-shimmer"></div>
                        <div class="shimmer"></div>
                      </li>
                    </ul>
                    <div *ngIf="ismemberLoaded && members.length === 0" class="no-result-wrapper">
                      <h5>No details found!</h5>
                      <p class="error-msg" *ngIf="errorMessage !== null">
                        <small>{{ errorMessage }}</small>
                      </p>
                    </div>
                    <div *ngIf="ismemberLoaded && members.length > 0 && !isEcardLoaded" class="spinner-wrap">
                      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                    </div>
                    <ul class="inner-cards" *ngIf="ismemberLoaded">
                      <li class="col" *ngFor="let member of members">
                        <div class="d-flex align-items-center">
                          <img
                          *ngIf="member.relationShipId === 'SELF' && member.sex==='M'"
                          class="d-flex align-self-start rounded mr-3"
                          src="assets/images/husband.png"
                          alt=""
                          height="64"
                        />
                          <img
                          *ngIf="member.relationShipId === 'SELF' && member.sex==='F'"
                          class="d-flex align-self-start rounded mr-3"
                          src="assets/images/wife.png"
                          alt=""
                          height="64"
                        />
                        <img
                          *ngIf="member.relationShipId === 'DAUGHTER'"
                          class="d-flex align-self-start mr-3"
                          src="assets/images/daughter.png"
                          alt=""
                          height="64"
                        />
                        <img
                          *ngIf="member.relationShipId === 'SON'"
                          class="d-flex align-self-start mr-3"
                          src="assets/images/son.png"
                          alt=""
                          height="64"
                        />
                        <img
                          *ngIf="member.relationShipId === 'FATHER'"
                          class="d-flex align-self-start mr-3"
                          src="assets/images/father.png"
                          alt=""
                          height="64"
                        />
                        <img
                          *ngIf="member.relationShipId === 'MOTHER'"
                          class="d-flex align-self-start mr-3"
                          src="assets/images/mother.png"
                          alt=""
                          height="64"
                        />
                        <img
                          *ngIf="member.relationShipId === 'SPOUSE' && member.sex==='F'"
                          class="d-flex align-self-start mr-3"
                          src="assets/images/wife.png"
                          alt=""
                          height="64"
                        />
                        <img
                          *ngIf="member.relationShipId === 'SPOUSE' && member.sex==='M'"
                          class="d-flex align-self-start mr-3"
                          src="assets/images/husband.png"
                          alt=""
                          height="64"
                        />
                          <span class="field-data"
                            >{{ member.insuredName }}
                            <small>{{ member.relationShipId }} - {{ member.age }} Years</small>
                          </span>
                        </div>
                        <span class="interact-claim">
                          <a
                            (click)="showEcard(member.uhid)"
                            class="ecard-button"
                            matTooltip="Click View to open ecard."
                            matTooltipPosition="above"
                          >
                            <img src="assets/images/download.png" height="24" width="24">
                          </a>
                          <a
                            (click)="emailEcard(member)"
                            href="#"
                            data-toggle="modal"
                            data-target="#view-print-claim"
                            class="ecard-button"
                            matTooltip="Click Email to receive ecard to mail."
                            matTooltipPosition="above"
                          >
                          <img src="assets/images/mail.png" height="24" width="24">
                            
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
          
     
      <div
        *ngIf="currentPolicy.viewPolicyCoverage !== undefined && !currentPolicy.viewECard"
        class="card card-style-1 col-xs-12 col-md-6 col-lg-4"
      >
        <div class="no-result-wrapper">
          <h5>This feature not Allowed for selected policy.</h5>
        </div>
      </div>
    </div>
  </div>

</div>





<!-- View Print Claim Modal -->
<div
  class="modal fade"
  id="view-print-claim"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  data-keyboard="false"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ memberToEmail.insuredName }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <div *ngIf="isEmailSent" class="my-5">
            <h6 class="text-success">Email sent successfully.</h6>
          </div>
          <div *ngIf="!isEmailSent && errorOccuredViaEmail" class="my-5">
            <h6 class="text-danger">Error Occured.</h6>
          </div>
          <span
            *ngIf="isEmailSent !== null && !isEmailSent && !errorOccuredViaEmail"
            class="spinner-border text-secondary my-5"
            role="status"
            aria-hidden="true"
          ></span>
        </div>

        <div class="form-group" *ngIf="isEmailSent === null && !isEmailSent">
          <label>Enter Email to download the e-card</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="email"
            placeholder="Email"
            [value]="memberToEmail.emailID"
            (input)="emailInput($event)"
          />
        </div>
        <p *ngIf="invalidEmail" class="error-msg">
          <small>{{ invalidEmailMsg }}</small>
        </p>
      </div>
      <div class="modal-footer" *ngIf="isEmailSent === null && !isEmailSent">
        <button type="button" [disabled]="invalidEmail" (click)="emailSubmit()" class="btn btn-primary">
          Send
        </button>
      </div>
    </div>
  </div>
</div>
